// /src/components/ChatInput/ChatInput.tsx

// What? A component for the chat input section of the application.
// Why? To allow users to input their questions and send them to the API.
// How?
// - Uses a TextareaAutosize for the input field.
// - Provides a send button to submit the question.
// - Handles the Enter key press to send the question.
// - Clears the input field after sending.

import { useState } from "react";

import React from "react";
import { Box, TextareaAutosize, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import styles from "./ChatInput.module.css";

// How to instantiate:
// <ChatInput
//   onSend={onSend}
//   disabled={disabled}
//   placeholder={placeholder}
//   clearOnSend={clearOnSend}
// />
interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
}

// What? A component for the chat input section of the application.
// Why? To allow users to input their questions and send them to the API.
// How?
// - Uses a TextareaAutosize for the input field.
// - Provides a send button to submit the question.
// - Handles the Enter key press to send the question.
// - Clears the input field after sending.

export const ChatInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
    const [message, setMessage] = useState<string>("");

    const sendDisabled = disabled || !message.trim();

    const sendMessage = () => {
        if (sendDisabled) {
            return;
        }

        onSend(message);

        if (clearOnSend) {
            setMessage("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendMessage();
        }
    };

    const onMessageChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;

        if (!newValue) {
            setMessage("");
        } else if (newValue.length <= 1000) {
            setMessage(newValue);
        }
    };

    return (
        <Box display="flex" className={styles.questionInputContainer}>
            <TextareaAutosize
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                minRows={4}
                maxRows={4}
                style={{ resize: "none", border: "none", width: "100%" }}
                value={message}
                onChange={onMessageChange}
                onKeyDown={onEnterPress}
            />
            <Box className={styles.questionInputButtonsContainer}>
                <IconButton
                    className={`${styles.questionInputSendButton} ${sendDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                    aria-label="Send message button"
                    onClick={sendMessage}
                    disabled={sendDisabled}
                >
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};
