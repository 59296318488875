// /src/components/Upperbar.tsx

// What? A component for the upper bar of the application.
// Why? To display the upper bar including the logo and main user settings items. Also manages the theme (light/dark mode).
// How?
// - Manages dark mode state using useState, allowing for dynamic theme switching
// - Initializes theme based on system preference or stored preference using useEffect
// - Enables navigation to home page when logo is clicked using useNavigate
// - Provides a Switch component for users to manually toggle between dark and light themes
// - Displays the application logo using an imported image path
// - Implements local storage to persist user's theme preference across sessions
// - Dynamically applies the chosen theme to the document root for consistent styling
// This implementation creates a flexible and user-friendly upper bar that adapts to user preferences
// and provides easy navigation, fulfilling the component's purpose of managing the application's
// main settings and branding in the upper bar.

import React from 'react';
import { Link } from 'react-router-dom';
import logoImagePath from '../Icons/Metalmind.png';
import '../styles/Upperbar.css';
import UpperbarMenu from './UpperBarMenu';

interface UpperbarProps {}

const Upperbar: React.FC<UpperbarProps> = () => {
  
  return (
    <div className="upper-bar">
      <Link to='/'>
        <img
          src={logoImagePath}
          alt="Metalmind Logo"
          className="upper-bar-logo"
        />
      </Link>
      <UpperbarMenu />
    </div>
  );
};

export default Upperbar;