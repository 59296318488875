// /src/components/Modals/Integrations/ConfigBlock.tsx

// What? A component for rendering a block of configuration fields for an integration.
// Why? To allow users to configure the integration settings in a structured manner.
// How?
// - Uses FieldRenderer for each field in the configuration.
// - Provides functions to add and remove items from array fields.

import React from 'react';
import { Button } from '@mui/joy';
import DeleteIcon from '@mui/icons-material/Delete';
import FieldRenderer from './FieldRenderer';
import { IntegrationConfigMeta, IntegrationFieldMeta } from '../../../common/types';

// How to instantiate:
// <ConfigBlock
//   index={index}
//   config={config}
//   configMeta={configMeta}
//   errors={errors}
//   onChange={onChange}
//   onAddArrayItem={onAddArrayItem}
//   onRemoveArrayItem={onRemoveArrayItem}
// />
interface ConfigBlockProps {
  index: number;
  config: any;
  configMeta: IntegrationConfigMeta;
  errors: { [key: string]: string };
  onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onAddArrayItem: (configIndex: number, fieldName: string) => void;
  onRemoveArrayItem: (configIndex: number, fieldName: string, arrayIndex: number) => void;
}

// What? A component for rendering a block of configuration fields for an integration.
// Why? To allow users to configure the integration settings in a structured manner.
// How?
// - Uses FieldRenderer for each field in the configuration.
// - Provides functions to add and remove items from array fields.
const ConfigBlock: React.FC<ConfigBlockProps> = ({
  index,
  config,
  configMeta,
  errors,
  onChange,
  onAddArrayItem,
  onRemoveArrayItem,
}) => {
  return (
    <div className="config-block">
      {configMeta.fields.map((field: IntegrationFieldMeta) => (
        <FieldRenderer
          key={`${field.name}-${index}`}
          field={field}
          value={config[field.name]}
          error={errors[`${field.name}-${index}`]}
          onChange={(e) => onChange(e, index)}
          onAddArrayItem={() => onAddArrayItem(index, field.name)}
          onRemoveArrayItem={(arrayIndex) => onRemoveArrayItem(index, field.name, arrayIndex)}
        />
      ))}
    </div>
  );
};

export default ConfigBlock;