// /src/components/Modals/AddUserModal.tsx

// What? A modal for adding a new user to the application.
// Why? To allow users to add new users to the application.
// How?
// - Uses a Modal component to display the modal.
// - Uses a ModalDialog component to display the modal content.
// - Uses a FormControl component to display the form.
// - Uses a FormLabel component to display the label for the email input.
// - Uses an Input component to display the email input.

import React, { useEffect, useState } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import { Button, CircularProgress, Select, Option } from '@mui/joy';

// How to instantiate:
// <AddUserModal
//   open={open}
//   handleAddUser={handleAddUser}
//   onClose={onClose}
//   buttonDisabled={buttonDisabled}
//   buttonLabel={buttonLabel}
// />
interface AddUserModalProps {
    open: boolean;
    handleAddUser: (event: React.FormEvent<HTMLFormElement>) => void;
    onClose: () => void;
    buttonDisabled: boolean;
    buttonLabel: React.ReactNode;
}

// What? A modal for adding a new user to the application.
// Why? To allow users to add new users to the application.
// How?
// This component implements a modal for adding a new user by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Displaying a form within the modal with fields for email and role
// 3. Managing form state (email and role) using React hooks
// 4. Handling form submission and passing data to the parent component
// 5. Providing options to submit the form or cancel the operation
// 6. Resetting the form state when the modal is closed
// 
// The component uses various MUI Joy components to create a consistent UI,
// including Modal, ModalDialog, FormControl, Input, and Select.
const AddUserModal: React.FC<AddUserModalProps> = ({ open, handleAddUser, onClose, buttonDisabled, buttonLabel }) => {
  const [addUserOpen, setAddUserOpen] = useState<boolean>(open);
  const [role, setRole] = useState<string>('user');  // Set default role to "user"
  const [email, setEmail] = useState<string>('');    // Add state for email input

  useEffect(() => {
    setAddUserOpen(open);
    if (!open) {
      // Reset the role and email when the modal is closed
      resetForm();
    }
  }, [open]);

  // Reset the form fields
  const resetForm = () => {
    setRole('user');  // Reset role to default "user"
    setEmail('');     // Reset email input
  };

  // Update the role when a new option is selected
  const handleRoleChange = (value: string) => {
    setRole(value);
  };

  return (
    <Modal open={addUserOpen} onClose={onClose}>
      <ModalDialog className="modal">
        <DialogTitle>Add new user</DialogTitle>
        <form onSubmit={(event) => {
          event.preventDefault();
          handleAddUser(event);  // Submit the form
        }}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                className="inputField"
                name="userEmail"
                autoFocus
                required
                placeholder="Email"
                value={email}              // Bind email to state
                onChange={(e) => setEmail(e.target.value)}  // Update email state
              />
              </FormControl>

            {/* Ensure the role is correctly managed by the Select component */}
            <Select
              className="selectButton"
              value={role}  // Set the selected role
              onChange={(event, newValue) => handleRoleChange(newValue as string)}
              placeholder="Role"
            >
              <Option value="admin">Admin</Option>
              <Option value="editor">Editor</Option>
              <Option value="user">User</Option>
            </Select>

            {/* Hidden input to make sure the role is passed in the form submission */}
            <input type="hidden" name="role" value={role} />

            <div className='btnCont'>
              <Button variant="solid" color="neutral" onClick={onClose} disabled={buttonDisabled}>Cancel</Button>
              <Button variant="solid" color="primary" className='primaryBtn' type="submit" disabled={buttonDisabled}>
                {buttonLabel} {/* Spinner or label based on parent state */}
              </Button>
            </div>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default AddUserModal;