import React, { useEffect, useState } from "react";
import { useFetchStripePricingTable } from "../services/api"; // Import the new API method
import "../styles/UserSubscriptionAndBilling.css";
import "../styles/WorkspaceSubscription.css";
import PageLoading from "../components/PageLoading";
import PageError from "../components/PageError";

interface SubscriptionPricingData {
  user_id: string;
  "publishable-key": string;
  "pricing-table-id": string;
  customer_portal_url?: string;
}

const UserSubscriptionAndBilling: React.FC = () => {
  const fetchStripePricingTable = useFetchStripePricingTable(); // Hook for fetching subscription data
  const [pricingData, setPricingData] = useState<SubscriptionPricingData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const scriptId = "stripe-pricing-table-script";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const data = await fetchStripePricingTable();
        setPricingData(data); // Save data
        if (data.customer_portal_url) {
          // Redirect directly to Stripe's customer portal
          window.location.href = data.customer_portal_url;
        }
      } catch (err: any) {
        console.error("Error details:", err);
        setError(err.message || "An unknown error occurred.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  if (loading) {
    return <PageLoading/>;
  }
  if (error) {
    return <PageError>Error: {error}</PageError>;
  }
  if (!pricingData) {
    return <PageError>No data available.</PageError>;
  }
  if(pricingData.customer_portal_url) {
    return <PageLoading>Redirecting...</PageLoading>
  }

  return (
    <div className="page-centered">
      <div className="pricing-container">
        <h1 className="page-title">Subscription Pricing</h1>
        <stripe-pricing-table
          pricing-table-id={pricingData["pricing-table-id"]}
          publishable-key={pricingData["publishable-key"]}
          client-reference-id={pricingData.user_id} 
        />
      </div>
    </div>
  );
};

export default UserSubscriptionAndBilling;
