// src/views/WorkspaceFileRender.tsx

// What? A React component for rendering a PDF file in a workspace.
// Why? To allow the user to view and download a PDF file.
// How?
// - Fetches the PDF file using workspaceCitationReadAPI.
// - Creates an object URL for the PDF file.
// - Displays the PDF file in an iframe.
// - Provides a download link for the original file.

import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Button, CircularProgress, Select, Option } from '@mui/joy';

import { useWorkspaceCitationRead, useWorkspaceSourceFileDownload } from '../services/api';

const WorkspaceFileRender = () => {
	const { workspace_id } = useParams() as { workspace_id: string };
	const { file } = useParams() as { file: string };

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const sourceFileId = queryParams.get("source_file_id") as string || '';

	const workspaceCitationReadAPI = useWorkspaceCitationRead();
	const workspaceSourceFileDownloadAPI = useWorkspaceSourceFileDownload();

	const [pdfUrl, setPdfUrl] = useState<string | null>(null);

	// What? A function to fetch the PDF file from the API.
	// Why? To display the PDF file in the component.
	// How?
	// - Tries to fetch the PDF file using workspaceCitationReadAPI.
	// - Creates an object URL for the PDF file.
	// - Sets the PDF URL in the state.
	useEffect(() => {
	    const fetchPDF = async () => {
	    	try {
	        	const response = await workspaceCitationReadAPI(workspace_id, encodeURIComponent(file));
	        	const arrayBuffer = await response.arrayBuffer();
	        	const blob = new Blob([arrayBuffer], { type: response.headers.get('content-type') });

	        	// Create an object URL for the Blob
				const url = URL.createObjectURL(blob);
				setPdfUrl(url);
			} catch (error) {
				console.error('Error fetching PDF:', error);
			}
    	};

		fetchPDF();

	    // Cleanup the URL object on component unmount
	    return () => {
			if (pdfUrl) {
				URL.revokeObjectURL(pdfUrl);
			}
		};
	}, [workspace_id, file]);

	// What? A function to handle the download of the original file.
	// Why? To allow the user to download the original file.
	// How?
	// - Extracts the file name from the source file ID.
	// - Downloads the file using workspaceSourceFileDownloadAPI.
	// - Creates an object URL for the file.
	// - Provides a download link for the original file.
	const handleDownloadFile = async () => {
		let fileName: string = sourceFileId.split("/").pop() as string;

		const response = await workspaceSourceFileDownloadAPI(workspace_id, encodeURIComponent(sourceFileId))
		const arrayBuffer = await response.arrayBuffer();
		const blob = new Blob([arrayBuffer], { type: response.headers['content-type'] });

		const url = URL.createObjectURL(blob);

		// Fake click download link
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

	    URL.revokeObjectURL(url);
	};

	return (
		<div style={{ position: "relative", height: "100vh" }}>
			{sourceFileId && (<Button onClick={handleDownloadFile} style={{position: "absolute", top: "10px", right: "10px", zIndex: 10}}>
				Download Original
			</Button>)}
			<div style={{ height: '100%'}}>
				{pdfUrl ? (
					<iframe
					src={pdfUrl}
					width="100%"
					height="100%"
	        		/>
	      		) : (
	        	<p>Loading PDF...</p>
	      		)}
	    	</div>
    	</div>
  	);
};

export default WorkspaceFileRender;




