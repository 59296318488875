// /src/components/ErrorMessageProvider.tsx

// What? A provider for error messages.
// Why? To display error messages to the user.
// How?
// - Creates an ErrorMessageContext using React's createContext to manage the state of error messages across components.
// - Implements a registerError function to add new error messages to the state.
// - Uses useState hook to maintain an array of error messages.
// - Renders a Box component positioned at the bottom-right of the screen to contain error messages.
// - Maps over the errors array to render each error as an Alert component.
// - Includes an IconButton within each Alert for users to dismiss individual error messages.
// - Implements a removeError function to remove specific errors when dismissed.

import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Alert, IconButton } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

interface ErrorMessageContextType {
  registerError: (error: string) => void;
}

const defaultContext: ErrorMessageContextType = {
  registerError: () => {
    throw new Error("setError function must be used within ErrorMessageProvider");
  },
};

const ErrorMessageContext = createContext<ErrorMessageContextType>(defaultContext);

interface ErrorMessageProviderProps {
  children: ReactNode;
}

export const ErrorMessageProvider : React.FC<ErrorMessageProviderProps> = ({ children }) => {
  const [errors, setErrors] = useState<string[]>([])

  const registerError = (error: string) => {
  	setErrors(prevErrors => [...prevErrors, error]);
  };

  const removeError = (index: number) => {
    setErrors(prevErrors => prevErrors.filter((_, i) => i !== index));
  };

  return (
    <ErrorMessageContext.Provider value={{ registerError }}>
      {children}

      {/* Display errors as floating alerts */}
      <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {errors.map((error, index) => (
          <Alert
            key={index}
            color="danger"
            endDecorator={
              <IconButton
                aria-label="close"
                size="sm"
                onClick={() => removeError(index)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {error}
          </Alert>
        ))}
      </Box>
    </ErrorMessageContext.Provider>
  );
};

export const useErrorMessageProvider = () => {
  const context = useContext(ErrorMessageContext);
  if (!context) {
    throw new Error('useErrorProvider must be used within a ErrorMessageProvider');
  }
  return context;
};

export default ErrorMessageProvider