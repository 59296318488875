// src/config/integrationsConfig.ts

// What? A configuration file for the integrations.
// Why? To define the available integrations and their properties.
// How?
// - Defines the IntegrationType interface to specify the structure of an integration.
// - Provides a list of available integrations with their names, descriptions, icons, types, configurability,
//   multiple configuration support, and the object type for configuration.

import React from 'react';
import {
  SharePointIcon,
  URLWebCrawlerIcon,
} from '../common/icons';
import { IntegrationType } from '../common/types';

const availableIntegrations: IntegrationType[] = [
  {
    name: 'SharePoint',
    description: 'Connect to MS SharePoint sites',
    icon: <SharePointIcon width="24" height="24" />, 
    type: 'sp_site',
    configurable: true,
    allowMultipleConfigs: true,
    configureObjectType: 'SharepointConfig',
  },
  {
    name: 'URL Web Crawler',
    description: 'Crawl specified URLs for information',
    icon: <URLWebCrawlerIcon width="24" height="24" />,
    type: 'url_crawler',
    configurable: true,
    allowMultipleConfigs: false,
    configureObjectType: 'URLCrawlerConfig',
  },
];

export default availableIntegrations;