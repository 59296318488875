
// What? The main app component.
// Why? To define the main routes and components of the app.
// How?
// - Imports the necessary libraries.
// - Sets up the routes and components.
// - Renders the main components.

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import './App.css';

// Import views
import UserWorkspaces from './views/UserWorkspaces';
import Chat from './views/Chat/Chat';
import Workspace from './views/Workspace';
import WorkspaceFiles from './views/WorkspaceFiles';
import WorkspaceUsers from './views/WorkspaceUsers';

import WorkspaceSources from './views/WorkspaceSources';
import WorkspaceFileRender from './views/WorkspaceFileRender';

// Nac container
import NavContainer from './components/NavContainer';
import NavContainerNoSidebar from './components/NavContainerNoSidebar';
import AuthProvider from './components/AuthProvider';
import ErrorMessageProvider from './components/ErrorMessageProvider';
import { WorkspaceProvider } from './providers/WorkspaceProvider'; 

// Stripe
import UserStats from './views/UserStats';
import UserSubscriptionAndBilling from './views/UserSubscriptionAndBilling';

const App: React.FC = () => {
  return (
    <>
      <ErrorMessageProvider>
        <Router>
          <Routes>
            {/* Protected Routes */}
            <Route path="*" element={
              <AuthProvider>
                <Routes>
                   
                  {/* Home */}
                  <Route path="/" element={<NavContainerNoSidebar><UserWorkspaces /></NavContainerNoSidebar>} />
                  <Route path="/stats" element={<NavContainerNoSidebar><UserStats /></NavContainerNoSidebar>} />
                  <Route path="/subscription" element={<NavContainerNoSidebar><UserSubscriptionAndBilling /></NavContainerNoSidebar>} />

                  <Route path="/workspace/:workspace_id" element={
                    <WorkspaceProvider>
                      <NavContainer>
                        <Outlet/>
                      </NavContainer>
                    </WorkspaceProvider>
                  }>
                    <Route path="" element={<Workspace />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="users" element={<WorkspaceUsers />} />
                    <Route path="sources" element={<WorkspaceSources />} />
                    <Route path="files" element={<WorkspaceFiles />} />
                    <Route path="file/:file" element={<WorkspaceFileRender />} />
                  </Route>
                </Routes>
              </AuthProvider>
              }/>
          </Routes>
        </Router>
      </ErrorMessageProvider>
    </>
  );
};
export default App;