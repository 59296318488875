// /src/components/Modals/IntegrationModal.tsx

// What? A modal for configuring an integration.
// Why? To allow users to configure an integration.
// How?
// - Uses a Modal component to display the modal.
// - Uses a ModalDialog component to display the modal content.
// - Uses a FormControl component to display the form.
// - Uses a FormLabel component to display the label for the integration.
// - Uses a ConfigBlock component to display the configuration fields for the integration.

// The IntegrationModal creates a nested interface by:
// 1. Accepting an 'integration' prop that contains the configuration metadata
// 2. Using the ConfigBlock component to render each section of the configuration
// 3. Handling nested fields within each ConfigBlock, including arrays and objects
// 4. Dynamically generating form inputs based on the field types in the metadata
// 5. Maintaining a state object that mirrors the structure of the input data
// 
// The component treats the input data structure as follows:
// - It expects a hierarchical object structure defined in the integration's metadata
// - Top-level fields are rendered as separate ConfigBlock components
// - Nested fields (objects) are rendered as sub-sections within their parent ConfigBlock
// - Array fields are rendered with add/remove functionality for dynamic list management
// - The component updates its internal state to match this structure as users interact with the form
// - When saving, it constructs a payload that preserves the nested structure of the configuration
// - This payload is then passed to the parent component for processing

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalDialog,
  DialogTitle,
  Stack,
  Button,
  CircularProgress
} from '@mui/joy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConfigBlock from './Integrations/ConfigBlock';
import '../../styles/IntegrationModal.css';
import { Integration, IntegrationConfigMetadata, IntegrationConfigMeta } from '../../common/types';
import { useUpdateIntegration, useCreateIntegration } from '../../services/api';

// How to instantiate:
// <IntegrationModal
//   open={open}
//   integration={integration}
//   workspaceId={workspaceId}
//   onClose={onClose}
//   onSave={onSave}
// />
interface IntegrationModalProps {
    open: boolean;
    integration: Integration;
    workspaceId: string;
    onClose: () => void;
    onSave: (
      payload: Partial<Integration>,
      onSuccess: () => void,
      onError: (error: string) => void
    ) => void;
    error: string | null;
  }

type ConfigObject = {
  [key: string]: any;
};

// What? A modal for configuring an integration.
// Why? To allow users to configure an integration.
// How?
// This component implements a modal for configuring an integration by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Displaying a form within the modal with fields for integration configuration
// 3. Managing form state (integration configuration) using React hooks
// 4. Handling form submission and passing data to the parent component
// 5. Providing options to submit the form or cancel the operation
const IntegrationModal: React.FC<IntegrationModalProps> = ({
  open,
  integration,
  workspaceId,
  onClose,
  onSave
}) => {
  const [configData, setConfigData] = useState<any[]>(integration.config || [{}]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formDirty, setFormDirty] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const configMeta = integration.configureObjectType
    ? IntegrationConfigMetadata[integration.configureObjectType]
    : undefined;

  // Initialize the config data with default values for required fields
  const initializeConfig = (config: ConfigObject): ConfigObject => {
    const newConfig = { ...config };

    if (configMeta) {
      configMeta.fields.forEach((field) => {
        if (
          field.type === 'array' &&
          field.required &&
          (!newConfig[field.name] || newConfig[field.name].length === 0)
        ) {
          // Initialize the required array field with one empty item
          newConfig[field.name] = [{}];
        }
      });
    }

    return newConfig;
  };

  // Set a nested value in the config data
  const setNestedValue = (obj: any, path: string[], value: any): any => {
    if (path.length === 0) {
      return value;
    }

    const [key, ...rest] = path;
    const index = parseInt(key, 10);

    if (!isNaN(index) && Array.isArray(obj)) {
      // Key is an array index, and obj is an array
      const newArray = [...obj];
      newArray[index] = setNestedValue(
        obj[index] !== undefined ? obj[index] : {},
        rest,
        value
      );
      return newArray;
    } else {
      // Key is an object property
      return {
        ...obj,
        [key]: setNestedValue(
          obj[key] !== undefined ? obj[key] : Array.isArray(rest[0]) ? [] : {},
          rest,
          value
        ),
      };
    }
  };

  // Reset errors and ensure configData is properly initialized
  useEffect(() => {
    setErrors({});
    setErrors({});

    const initialConfigData = integration.config || [{}];

    const initializedConfigData = initialConfigData.map((config: ConfigObject) =>
      initializeConfig(config)
    );

    setConfigData(initializedConfigData);
  }, [integration]);

  // Log when the form is dirty
  useEffect(() => {
    if (formDirty) {
      console.log('Form has been modified and is now dirty');
    }
  }, [formDirty]);

  // Handle changes in the form fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    const errorKey = `${name}-${index}`;
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[errorKey];
      return newErrors;
    });

    setConfigData((prevConfigs) => {
      const newConfigs = [...prevConfigs];
      const path = name.split('.');
      newConfigs[index] = setNestedValue(newConfigs[index], path, newValue);
      return newConfigs;
    });

    setFormDirty(true); // Mark the form as dirty after a change
  };

  // Add an item to an array field
  const handleAddArrayItem = (configIndex: number, fieldName: string) => {
    setConfigData((prevConfigs) => {
      const newConfigs = [...prevConfigs];
  
      const fieldValue = newConfigs[configIndex][fieldName] || [];
      
      // Check if it's an array of objects or plain array
      const isObjectArray = configMeta?.fields.find(field => field.name === fieldName)?.fields;
  
      // If it's an array of objects, add an empty object; otherwise, add an empty string for plain arrays
      const newItem = isObjectArray ? {} : '';
      
      newConfigs[configIndex][fieldName] = [...fieldValue, newItem];
  
      return newConfigs;
    });
  
    setFormDirty(true); // Mark the form as dirty after adding an item
  };
  
  // Remove an item from an array field
  const handleRemoveArrayItem = (configIndex: number, fieldName: string, arrayIndex: number) => {
    setConfigData((prevConfigs) => {
      const newConfigs = [...prevConfigs];
      newConfigs[configIndex][fieldName] = newConfigs[configIndex][fieldName].filter(
        (_: any, i: number) => i !== arrayIndex
      );
      return newConfigs;
    });
  
    setFormDirty(true); // Mark the form as dirty after removing an item
  };

  // Strip the config data to the meta fields
  const stripConfigToMeta = (config: any, configMeta: IntegrationConfigMeta): any => {
    const strippedConfig: any = {};
  
    configMeta.fields.forEach((fieldMeta) => {
      if (fieldMeta.name in config) {
        if (fieldMeta.type === 'array' && Array.isArray(config[fieldMeta.name])) {
          // For arrays, ensure we only include valid array items
          strippedConfig[fieldMeta.name] = config[fieldMeta.name].map((item: any) => {
            // Recursively strip down nested fields if applicable
            return fieldMeta.fields ? stripConfigToMeta(item, { fields: fieldMeta.fields }) : item;
          });
        } else if (fieldMeta.fields) {
          // Handle nested objects
          strippedConfig[fieldMeta.name] = stripConfigToMeta(config[fieldMeta.name], {
            fields: fieldMeta.fields,
          });
        } else {
          // Simple fields
          strippedConfig[fieldMeta.name] = config[fieldMeta.name];
        }
      }
    });
  
    return strippedConfig;
  };

  // Handle form submission
  const handleSubmit = () => {
    const newErrors: { [key: string]: string } = {};
  
    // Perform validation
    if (configMeta) {
      configData.forEach((config, index) => {
        configMeta.fields.forEach((field) => {
          const fieldValue = config[field.name];
          if (field.required && (!fieldValue && fieldValue !== 0)) {
            newErrors[`${field.name}-${index}`] =
              field.validationMessage || `${field.label} is required.`;
          }
          if (field.type === 'number') {
            if (fieldValue === '' || fieldValue === undefined || fieldValue === null) {
              newErrors[`${field.name}-${index}`] = 'Field is required.';
            } else if (field.min !== undefined && fieldValue < field.min) {
              newErrors[`${field.name}-${index}`] = `Must be at least ${field.min}.`;
            } else if (field.max !== undefined && fieldValue > field.max) {
              newErrors[`${field.name}-${index}`] = `Must be no more than ${field.max}.`;
            }
          }
        });
      });
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    if (!configMeta) {
      setError("Configuration metadata is missing.");
      return;
    }
  
    // Strip and flatten the config fields and merge them with the top-level payload fields
    const strippedConfigData = configData.map((config) => stripConfigToMeta(config, configMeta));
    const flattenedConfig = strippedConfigData[0] || {};
  
    const payload = {
      name: integration.name,
      type: integration.type,
      ...flattenedConfig,
    };
  
    // Pass the payload back to the parent for saving, and handle success and error
    onSave(payload, 
      () => { // onSuccess callback
        setIsSaving(false); // Stop spinner
        onClose(); // Close the modal on success
      },
      (errorMessage: string) => { // onError callback
        setIsSaving(false); // Stop spinner
        setError(errorMessage); // Display the error in the modal
      }
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog className="modal">
        <DialogTitle>{integration.enabled ? 'Configure' : 'Add'} {integration.name}</DialogTitle>
        <div className="modal-content">
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <Stack spacing={2}>
              {configData.map((config, index) => (
                <ConfigBlock
                  key={index}
                  index={index}
                  config={config}
                  configMeta={configMeta!}
                  errors={errors}
                  onChange={handleChange}
                  onAddArrayItem={handleAddArrayItem}
                  onRemoveArrayItem={handleRemoveArrayItem}
                />
              ))}
            </Stack>
          </form>
        </div>

        <div className="modal-actions">
          <Button className="secondaryBtn" variant="solid" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="primaryBtn"
            variant="solid"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={isSaving || !formDirty} // Only allow saving if form is dirty
          >
            {isSaving ? <CircularProgress size="sm" className="customSpinner" /> : 'Save'}
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
};

export default IntegrationModal;