import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/joy/Tooltip';
import '../styles/Upperbar.css';
import '../index.css'
import IconButton from '@mui/joy/IconButton';
import { Home, LightMode, DarkMode, Upgrade, CreditCard, Logout, BarChart } from '@mui/icons-material';

interface UpperbarMenuProps {}

const UpperbarMenu: React.FC<UpperbarMenuProps> = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(localStorage.getItem('theme') === 'dark');
  
  // Detect system preference and apply it or use stored theme preference
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setIsDarkMode(storedTheme === 'dark');
      document.documentElement.setAttribute('data-theme', storedTheme);
    } else {
      const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDarkMode(systemPrefersDark);
      document.documentElement.setAttribute('data-theme', systemPrefersDark ? 'dark' : 'light');
      localStorage.setItem('theme', systemPrefersDark ? 'dark' : 'light');  // Save the preference to local storage
    }
  }, []);

  // Toggle between dark and light theme
  const toggleTheme = () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    setIsDarkMode(!isDarkMode); // Update React state
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);  // Save the preference to local storage
  };

  return (
    <div className="upper-bar-menu">
      <Tooltip title="Home">
        <Link to='/'>
          <IconButton>
            <Home/>
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Statistics">
        <Link to='/stats'>
          <IconButton variant="plain" color="neutral">
            <BarChart />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Subscription and billing">
        <Link to='/subscription'>
          <IconButton variant="plain" color="neutral">
            <CreditCard />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}>
        <IconButton onClick={toggleTheme} >
          {isDarkMode ? <LightMode /> : <DarkMode />}
        </IconButton>
      </Tooltip>
      <Tooltip title="Log out">
        <Link to='/logout'>
          <IconButton>
            <Logout />
          </IconButton>
        </Link>
      </Tooltip>
    </div>
  );
};

export default UpperbarMenu;