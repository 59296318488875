// /src/components/Modals/Integrations/FieldRenderer.tsx

// What? A component for rendering a field in an integration configuration.
// Why? To allow users to configure the integration settings in a structured manner.
// How?
// - Uses a FormControl for the field.
// - Provides a FormLabel for the field.
// - Provides an Input for the field.
// - Provides a Checkbox for the field.
// - Provides a Button for the field.

import React from 'react';
import { FormControl, FormLabel, Input, Checkbox, Button } from '@mui/joy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import { IntegrationFieldMeta } from '../../../common/types';

// How to instantiate:
// <FieldRenderer
//   field={field}
//   value={value}
//   error={error}
//   onChange={onChange}
//   onAddArrayItem={onAddArrayItem}
//   onRemoveArrayItem={onRemoveArrayItem}
// />
interface FieldRendererProps {
  field: IntegrationFieldMeta;
  value: any;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddArrayItem: () => void;
  onRemoveArrayItem: (index: number) => void;
}

// What? A component for rendering a field in an integration configuration.
// Why? To allow users to configure the integration settings in a structured manner.
// How?
// - Renders different form elements based on the field type:
//   - For 'checkbox' type: Renders a Checkbox component
//   - For 'array' type with nested fields: Renders a list of sub-fields with add/remove buttons
//   - For other types: Renders an Input component
// - Wraps each field in a FormControl component for consistent styling
// - Displays a FormLabel with the field's label text
// - Shows an error message if the 'error' prop is provided
// - Handles onChange events for all input types
// - Provides functionality to add and remove items for array fields
const FieldRenderer: React.FC<FieldRendererProps> = ({
  field,
  value,
  error,
  onChange,
  onAddArrayItem,
  onRemoveArrayItem,
}) => {
  const hasError = !!error;

  return (
    <div className="formControlContainer">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>{field.label}</FormLabel>
      </div>

      {hasError && <div className="error-bubble">{error}</div>}

      {field.type === 'checkbox' ? (
        <FormControl>
          <Checkbox name={field.name} checked={!!value} onChange={onChange} />
        </FormControl>
      ) : field.type === 'array' && field.fields ? (
        // For arrays of objects (with nested fields)
        <>
          {(value || []).map((item: any, arrayIndex: number) => (
            <div key={`${field.name}-${arrayIndex}`} className="subfield-container" style={{ marginBottom: '10px' }}>
              {field.fields!.map((subField) => (
                <FieldRenderer
                  key={`${subField.name}-${arrayIndex}`}
                  field={{ ...subField, name: `${field.name}.${arrayIndex}.${subField.name}` }}
                  value={item[subField.name]}
                  error={error}
                  onChange={onChange}
                  onAddArrayItem={() => {}}
                  onRemoveArrayItem={() => {}}
                />
              ))}
              <Button 
                className="subfield-remove-button"
                onClick={() => onRemoveArrayItem(arrayIndex)} 
                variant="outlined" 
                color="danger"
              >
                <RemoveCircleOutlineIcon className="icon" />
              </Button>
            </div>
          ))}
          <Button 
            className="subfield-add-button" 
            onClick={onAddArrayItem} 
            variant="solid"
          >
            <AddCircleOutlineIcon className="icon" /> Add {field.label}
          </Button>
        </>
      ) : field.type === 'array' ? (
        // For plain arrays (like URLs)
        <>
          {(value || []).map((item: string, arrayIndex: number) => (
            <div key={`${field.name}-${arrayIndex}`} className="plain-array-item" style={{ marginBottom: '10px' }}>
              <FormControl>
                <Input
                    type="text"
                    name={`${field.name}.${arrayIndex}`}
                    value={typeof item === 'string' ? item : ''} // Ensure we're rendering strings
                    onChange={onChange}
                    placeholder={field.placeholder || ''}
                    className={hasError ? 'input-error' : ''}
                />
              </FormControl>
              <Button 
                className="array subfield-remove-button"
                onClick={() => onRemoveArrayItem(arrayIndex)} 
                variant="outlined" 
                color="danger"
              >
                <RemoveCircleOutlineIcon className="icon" />
              </Button>
            </div>
          ))}
          <Button 
            className="subfield-add-button" 
            onClick={onAddArrayItem} 
            variant="solid"
          >
            <AddCircleOutlineIcon className="icon" /> Add {field.label}
          </Button>
        </>
      ) : (
        // For simple fields
        <FormControl>
          <Input
            type={field.type === 'password' ? 'password' : field.type}
            name={field.name}
            value={value || ''}
            onChange={onChange}
            placeholder={field.placeholder || ''}
            className={hasError ? 'input-error' : ''}
          />
        </FormControl>
      )}
    </div>
  );
};

export default FieldRenderer;