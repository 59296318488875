// src/views/WorkspaceSubscription.tsx
import React, { useRef } from 'react';
import ResourceUsage from '../components/Subscription/ResourceUsage';
import UsageTrendsChart from '../components/Subscription/UsageTrendsChart';
import '../styles/WorkspaceSubscription.css';

const UserStats: React.FC = () => {
  return (
    <div className="page-centered">
      <h1 className="title">Stats</h1>
      <div className="content-box">
        <ResourceUsage />
        <UsageTrendsChart />
      </div>
    </div>
  );
};

export default UserStats;