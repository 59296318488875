// /src/components/Modals/ConfirmationModal.tsx

// What? A modal for confirming an action.
// Why? To allow users to confirm an action before it is executed.
// How?
// - Uses a Modal component to display the modal.
// - Uses a ModalDialog component to display the modal content.
// - Uses a FormControl component to display the form.
// - Uses a FormLabel component to display the label for the action.

import React, { useEffect } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import { Button, CircularProgress } from '@mui/joy';

// How to instantiate:
// <ConfirmationModal
//   open={open}
//   title={title}
//   message={message}
//   actionLabel={actionLabel}
//   cancelLabel={cancelLabel}
//   onAction={onAction}
//   onClose={onClose}

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  message?: string;  // Default message
  actionLabel?: string;  // Default confirm button label
  cancelLabel?: string;  // Default cancel button label
  onAction: () => Promise<void> | void;  // Can be a Promise (async) or a regular function
  onClose: () => void;
  loading?: boolean;  // Optional loading spinner controlled externally
  spinner?: React.ReactNode;  // Optional spinner to display while waiting for async action
}

// What? A modal for confirming an action.
// Why? To allow users to confirm an action before it is executed.
// How?
// This component implements a modal for confirming an action by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Displaying a form within the modal with a field for action confirmation
// 3. Managing form state (action confirmation) using React hooks
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  title,
  message = "Are you sure?",  // Default message
  actionLabel = "Confirm",  // Default action button label
  cancelLabel = "Cancel",  // Default cancel button label
  onAction,
  onClose,
  loading = false,  // Loading controlled by parent
  spinner,
}) => {

  // Ensure that when the modal closes, the parent state is reset
  useEffect(() => {
    if (!open) {
      // Parent should handle resetting any loading state
    }
  }, [open]);

  const handleActionClick = async () => {
    // Call the action passed in props
    await onAction();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog className="modal">
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={handleActionClick}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel className="formLabel">{message}</FormLabel>
            </FormControl>
            <div className='btnCont'>
              <Button variant="solid" color="neutral" onClick={onClose} disabled={loading}>
                {cancelLabel}
              </Button>
              <Button variant="solid" color="primary" type="button" disabled={loading} onClick={handleActionClick}>
                {loading && spinner ? spinner : actionLabel}
              </Button>
            </div>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModal;