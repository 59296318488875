// /src/services/api.ts

// What? A file for the API services.
// Why? To centralize API calls, promoting code reusability and simplifying data management across the application.
// How?
// - Defines custom hooks for each API endpoint, encapsulating the API call logic
// - Uses the useAuthFetch hook to make authenticated API calls
// - Returns memoized functions that execute the API requests
// - Provides type-safe interfaces for API responses using TypeScript
// - Centralizes API endpoint definitions, making it easier to manage and update
// - Allows components to easily fetch and manage data from the backend

import { useCallback } from 'react';
import { FileType, User, UserWorkspacesType, WorkspaceUser, WorkspaceType, ResourceUsageData, UsageTrendTimeframe, UsageTrendData, Integration } from '../common/types';
import { useAuthFetch } from '../utils/useAuthFetch'
import { response } from 'express';

/*
  Mock API Instructions:
  Endpoint for returning static data in the same format as the real APIs. For easy implementation in frontend rendering.
  Ignore dynamic endpoints that add, remove, or otherwise need to modify the database. 
  
  Naming conventions:
    API endpoint name: /api/workspace/user/list
    Function name: workspaceUserList
    Hook name: useWorkspaceUserList
*/

/* Helper function to format integration name */
const formatIntegrationName = (name: string): string => {
  return name.toLowerCase().replace(/\s+/g, '-'); // Convert to lowercase and replace spaces with dashes
};

/*
  User APIs
*/
// What? A hook for fetching the user's workspaces.
// Why? To retrieve the list of workspaces associated with the current user.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the workspaces.
export const useUserWorkspaces = () => {
  const authFetch = useAuthFetch();

  const userWorkspaces = async (): Promise<UserWorkspacesType[]> => {
    return await authFetch(`/api/user/workspaces`, "list workspaces") as UserWorkspacesType[];
  };
  return userWorkspaces;
};

/*
  Workspace APIs
*/
// What? A hook for creating a new workspace.
// Why? To add a new workspace to the user's list of workspaces.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that creates a new workspace.
export const useWorkspaceCreate = () => {
  const authFetch = useAuthFetch();

  const workspaceCreate = async (workspaceName: string): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/create`, "create workspace", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: workspaceName,
      }),
    }) as WorkspaceType;
  };

  return workspaceCreate;
};

// What? A hook for reading a workspace by its ID.
// Why? To retrieve detailed information about a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the workspace by ID.
export const useWorkspaceRead = () => {
  const authFetch = useAuthFetch();

  const workspaceRead = async (workspaceId: string): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/read?workspace_id=${workspaceId}`, "read workspace") as WorkspaceType;
  };

  return workspaceRead;
};

export const useWorkspaceUpdate = () => {
  const authFetch = useAuthFetch();

  const workspaceUpdate = async (
    workspaceId: string,
    workspaceData: { name: string; description: string; assistant_instructions: string }
  ): Promise<WorkspaceType> => {
    return await authFetch(`/api/workspace/update?workspace_id=${workspaceId}`, "update workspace", {
      method: 'PUT',  // PUT request for updates
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: workspaceData.name,
        description: workspaceData.description,
        assistant_instructions: workspaceData.assistant_instructions,
      }),
    }) as WorkspaceType;
  };

  return workspaceUpdate;
};

// What? A hook for deleting a workspace by its ID.
// Why? To remove a workspace from the user's list of workspaces.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that deletes the workspace by ID.
export const useWorkspaceDelete = () => {
  const authFetch = useAuthFetch();

  const workspaceDelete = async (workspaceId: string): Promise<void> => {
    return await authFetch(`/api/workspace/delete?workspace_id=${workspaceId}`, "delete workspace", {
      method: 'DELETE', 
      headers: {
        'Content-Type': 'application/json',
      }
    });
  };

  return workspaceDelete;
};

/*
 Workspace User APIs
*/
// What? A hook for listing users in a workspace.
// Why? To retrieve the list of users associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the users in the workspace. 
export const useWorkspaceUserList = () => {
  const authFetch = useAuthFetch();

  const workspaceUserList = async (workspaceId: string): Promise<WorkspaceUser[]> => {
    return await authFetch(`/api/workspace/user/list?workspace_id=${workspaceId}`, "list workspace users") as WorkspaceUser[];
  };

  return workspaceUserList;
};

export const useWorkspaceUserInviteSend = () => {
  const authFetch = useAuthFetch();

  const workspaceUserInviteSend = async (workspaceId: string, email: string, role: string) => {
    return await authFetch(`/api/workspace/user/invite/send?workspace_id=${workspaceId}`, "send user invite", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        role: role,
      }),
    });
  };

  return workspaceUserInviteSend;
};

/*
  Payment APIs
*/
// What? A hook for registering a payment for a workspace.
// Why? To record a payment made by a workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that registers the payment.
export const useWorkspacePaymentRegister = () => {
  const authFetch = useAuthFetch();
  
  const workspacePaymentRegister = async (workspaceId: string, subscription: string, amount: number) => {
    return await authFetch(`/api/workspace/payment/register?workspace_id=${workspaceId}`, "register payment", {
      method: 'POST',
      body: JSON.stringify({
        subscription: subscription,
        amount: amount,
      }),
    });
  };

  return workspacePaymentRegister;
};

export const useWorkspacePaymentList = () => {
  const authFetch = useAuthFetch();

  const workspacePaymentList = async (workspaceId: string) => {
    return await authFetch(`/api/workspace/payment/list?workspace_id=${workspaceId}`, "list payments");
  };

  return workspacePaymentList;
};

/*
  Workspace user permissions
*/
// What? A hook for updating the role of a user in a workspace.
// Why? To modify the permissions or roles of a user within a workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that updates the user's role in the workspace.
export const useWorkspaceUserUpdate = () => {
  const authFetch = useAuthFetch();

  const workspaceUserUpdate = async (workspaceId: string, role: string, userId: string) => {
    return await authFetch(`/api/workspace/user/update?workspace_id=${workspaceId}`, "update user permissions", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: role,
        id: userId,
      }),
    });
  };

  return workspaceUserUpdate;
}

// What? A hook for removing a user from a workspace.
// Why? To remove a user from a workspace, typically when the user is no longer associated with the workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that removes the user from the workspace.
export const useWorkspaceUserRemove = () => {
  const authFetch = useAuthFetch();

  const workspaceUserRemove = async (workspaceId: string, userId: string): Promise<void> => {
    return await authFetch(`/api/workspace/user/remove?workspace_id=${workspaceId}`, "remove user from workspace", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId })
    });
  };

  return workspaceUserRemove;
};

// What? A hook for accepting an invite to a workspace.
// Why? To allow a user to join a workspace after receiving an invite.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that accepts the invite to the workspace.
export const useWorkspaceUserAcceptInvite = () => {
  const authFetch = useAuthFetch();

  const workspaceUserAcceptInvite = async (workspaceId: string): Promise<UserWorkspacesType> => {
    return await authFetch(`/api/workspace/user/invite/accept?workspace_id=${workspaceId}`, "accept workspace invite", {
      method: 'POST',
    }) as UserWorkspacesType;
  };

  return workspaceUserAcceptInvite;
};

/*
  User APIs
*/
// What? A hook for removing a user by their ID.
// Why? To remove a user from the system, typically when the user is no longer active or should be removed from the system.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that removes the user by ID.
export const useRemoveUser = () => {
  const authFetch = useAuthFetch();

  const removeUser = async (id: string): Promise<void> => {
    return await authFetch(`/api/user/remove`, "delete user", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
  };

  return removeUser;
};

// What? A hook for reading the current user's information.
// Why? To retrieve the details of the currently authenticated user.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the user's information.
export const useReadUser = () => {
  const authFetch = useAuthFetch();

  const readUser = async (): Promise<User> => {
    return await authFetch(`/api/user/read`, "read user") as User;
  };

  return readUser;
};

/* 
  File APIs
*/
// What? A hook for listing files in a workspace.
// Why? To retrieve the list of files associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the files in the workspace.
export const useListFiles = () => {
  const authFetch = useAuthFetch();

  const listFiles = async (workspaceId: string): Promise<FileType[]> => {
    return await authFetch(`/api/workspace/file/list?workspace_id=${workspaceId}`, "list files") as FileType[];
  };

  return listFiles;
};

// What? A hook for deleting a file by its ID.
// Why? To remove a file from a workspace, typically when the file is no longer needed.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that deletes the file by ID.
export const useDeleteFile = () => {
  const authFetch = useAuthFetch();

  const deleteFile = async (workspaceId:string, fileId: string) => {
    return await authFetch(`/api/workspace/file/delete?workspace_id=${workspaceId}&file_path=${encodeURIComponent(fileId)}`, "delete file", {
      method: 'DELETE',
    });
  };

  return deleteFile;
};

/* 
  File Upload API 
*/
// What? A hook for uploading a file to a workspace.
// Why? To add a file to a workspace, typically for storing or processing.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that uploads the file to the workspace.
export const useUploadFile = () => {
  const authFetch = useAuthFetch();

  const uploadFile = async (workspaceId: string, file: File): Promise<FileType> => {
    const formData = new FormData();
    formData.append('file', file);

    return await authFetch(`/api/workspace/file/upload?workspace_id=${workspaceId}`, `upload file ${file.name}`, {
      method: 'POST',
      body: formData,
    },
    30000
    );
  };

  return uploadFile;
};

/*
  Get file bin
*/
// What? A hook for reading a citation from a file.
// Why? To retrieve detailed information about a specific citation from a file.
// How?
// - Uses the useAuthFetch hook to make the API call.
// - Returns a memoized function that fetches the citation from the file.
export const useWorkspaceCitationRead = () => {
  const authFetch = useAuthFetch("");

  const workspaceCitationRead = async (workspaceId: string, fileId: string): Promise<any> => {
    return await authFetch(`/api/workspace/citation/read?workspace_id=${workspaceId}&file_id=${fileId}`, "read file");
  };

  return workspaceCitationRead;
};

export const useWorkspaceSourceFileDownload = () => {
  const authFetch = useAuthFetch("");

  const workspaceSourceFileDownload = async (workspaceId: string, fileId: string): Promise<any> => {
    return await authFetch(`/api/workspace/source/file/download?workspace_id=${workspaceId}&file_id=${fileId}`, "download file");
  };

  return workspaceSourceFileDownload;
};

// What? A hook to retrieve resource usage data.
// Why? To display current resource usage and limits to the user.
// How? Returns a function that fetches mock resource usage data (to be replaced with actual API calls).
// TODO: Replace with actual API call.
export const useGetResourceUsage = () => {
  const authFetch = useAuthFetch();

  const getResourceUsage = async (): Promise<ResourceUsageData> => {
    const capacity: Record<string, any> = await authFetch(`/api/subscription/usage`, "retrieve subscription usage");
    
    return {
      "resources": [
        {
          name: "Workspaces",
          used: capacity["active_workspaces"],
          total: capacity["workspaces"],
          percentage: Math.round((capacity["active_workspaces"] / capacity["workspaces"]) * 100),
          message: ''
        }, {
          name: "Requests",
          used: capacity["used_requests"],
          total: capacity["monthly_requests"],
          percentage: Math.round((capacity["used_requests"] / capacity["monthly_requests"]) * 100),
          message: ''
        },
        {
          name: "Storage",
          used: Math.round(capacity["used_storage"]*100)/100,
          total: Math.round(capacity["gb_storage"]*100)/100,
          percentage: Math.round((capacity["used_storage"] / capacity["gb_storage"]) * 100),
          message: ''
        }
      ]
    }
  };
  return getResourceUsage;
};

// What? A hook to retrieve usage trend data.
// Why? To display historical usage trends to the user.
// How? Returns a function that fetches mock usage trend data (to be replaced with actual API calls).
// TODO: Replace with actual API call.
export const useGetUsageTrends = () => {
  const authFetch = useAuthFetch();

  const getUsageTrends = async (timeframe: UsageTrendTimeframe): Promise<UsageTrendData> => {
    // Function to calculate the date `days` before today and return it as a datetime string
    const calculateDateTime = (days: number): string => {
      const date = new Date();
      date.setDate(date.getDate() - days);
      return date.toISOString(); // Returns in "YYYY-MM-DDTHH:mm:ss.sssZ" format
    };

    let start_date: string;
    if (timeframe === '30') {
      start_date = calculateDateTime(30);
    } else if (timeframe === '90') {
      start_date = calculateDateTime(90);
    } else if (timeframe === '365') {
      start_date = calculateDateTime(365);
    } else {
      throw new Error("Invalid timeframe");
    }

    const end_date = new Date().toISOString();

    const data: number[] = await authFetch(`/api/subscription/usage-trends?start_date=${encodeURIComponent(start_date)}&end_date=${encodeURIComponent(end_date)}`, "retrieve subscription usage trends");

    // Format
    const labels = data.map((_, i) => `Week ${i + 1}`);
    return {
      labels,
      datasets: [
        {
          label: 'Requests Used',
          data: data,
          borderColor: 'rgb(255, 111, 0)',
          backgroundColor: 'rgba(255, 111, 0, 0.1)',
          tension: 0.1,
          fill: true,
        },
      ],
      options: {
        responsive: true,
        plugins: {
          legend: { position: 'top' },
          title: { display: true, text: 'Request Usage Over Time' },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: { display: true, text: 'Requests' },
          },
          x: {
            title: { display: true, text: 'Time' },
          },
        },
      },
    };
  };
  return getUsageTrends;
};

// What? A hook for retrieving integrations for a workspace.
// Why? To fetch and display the list of integrations associated with a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a memoized function that fetches the integrations for a given workspace ID.
// - The function is memoized using useCallback to optimize performance and prevent unnecessary re-renders.
export const useGetIntegrations = () => {
  const authFetch = useAuthFetch();

  const getIntegrations = useCallback(
    async (workspaceId: string): Promise<Integration[]> => {
      const response = await authFetch(`/api/workspace/source/list?workspace_id=${workspaceId}`, "list integrations");
      return response as Integration[];
    },
    [authFetch]
  );

  return getIntegrations;
};

// What? A hook for creating a new integration.
// Why? To add a new integration to a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that creates a new integration with the given type, workspace ID, and configuration data.
// - Formats the integration type to match the expected API format.
// - Handles errors and logs them to the console.
export const useCreateIntegration = () => {
  const authFetch = useAuthFetch();

  const createIntegration = async (integrationType: string, workspaceId: string, data: any): Promise<Integration> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      const response = await authFetch(`/api/workspace/source/create?workspace_id=${workspaceId}`, "create integration", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      return response as Integration;
    } catch (error: any) {
      console.error(`Error creating ${formattedType}:`, error);
      throw error;
    }
  };

  return createIntegration;
};

// What? A hook for updating an existing integration.
// Why? To modify the configuration or settings of an integration in a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that updates an integration with the given type, workspace ID, and updated configuration data.
// - Formats the integration type to match the expected API format.
// - Handles errors and logs them to the console.
export const useUpdateIntegration = () => {
  const authFetch = useAuthFetch();

  const updateIntegration = async (integrationType: string, workspaceId: string, data: any): Promise<Integration> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      const response = await authFetch(`/api/workspace/source/update?workspace_id=${workspaceId}`, "update integration", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      return response as Integration;
    } catch (error: any) {
      console.error(`Error updating ${formattedType}:`, error);
      throw error;
    }
  };

  return updateIntegration;
};

// What? A hook for deleting an existing integration.
// Why? To remove an integration from a specific workspace.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Returns a function that deletes an integration with the given type, workspace ID, and integration name.
// - Formats the integration type to match the expected API format.
// - Encodes the integration name to ensure proper URL formatting.
// - Handles errors and logs them to the console.
export const useDeleteIntegration = () => {
  const authFetch = useAuthFetch();

  const deleteIntegration = async (integrationType: string, workspaceId: string, integrationName: string): Promise<void> => {
    const formattedType = integrationType.toLowerCase().replace(/\s+/g, '-');
    try {
      await authFetch(`/api/workspace/source/remove?workspace_id=${workspaceId}&source_name=${encodeURIComponent(integrationName)}`, "delete integration", {
        method: 'DELETE',
      });
    } catch (error: any) {
      console.error(`Error deleting ${formattedType}:`, error);
      throw error;
    }
  };

  return deleteIntegration;
};

// What? A hook to fetch the Stripe subscription pricing table.
// Why? To centralize the API call for fetching subscription pricing data and ensure it uses the authenticated fetch utility.
// How?
// - Uses the useAuthFetch hook to make an authenticated API call.
// - Constructs the API URL dynamically with the provided return URL and user name.
// - Returns the subscription pricing data from the backend.
export const useFetchStripePricingTable = () => {
  const authFetch = useAuthFetch();

  const fetchStripePricingTable = async (): Promise<{
    user_id: string;
    "publishable-key": string;
    "pricing-table-id": string;
    customer_portal_url?: string;
  }> => {
    const returnUrl = encodeURIComponent(window.location.origin);
    const apiUrl = `/api/subscription/create-subscription-pricing-table?return_url=${returnUrl}`;

    try {
      const response = await authFetch(apiUrl, "fetch subscription pricing table", {});
      return response;
    } catch (err: any) {
      console.error("Error fetching subscription pricing table:", err);
      throw err; // Rethrow for the caller to handle
    }
  };

  return fetchStripePricingTable;
};
