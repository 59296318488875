// /src/components/Tooltip.tsx

// What? A tooltip component.
// Why? To display a tooltip when hovering over an element.
// How?
// - Uses the useState hook to manage the visibility state of the tooltip.
// - Uses the useEffect hook to handle click events outside the tooltip for closing.
// - Uses the useRef hook to create a reference to the tooltip element for DOM manipulation.
// - Implements click handling to toggle the tooltip's visibility.
// - Utilizes the Popper component from Material-UI for positioning the tooltip relative to its anchor.
// - Renders children (likely an icon or text) that triggers the tooltip on click.
// - Displays the provided content within the tooltip when it's open.
// This implementation creates an interactive tooltip that opens on click, closes when clicking outside,
// and provides a flexible way to display additional information or controls near the triggering element.
import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/joy';
import Popper from '@mui/material/Popper';
import { SvgIcon } from '@mui/material';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, onClose }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        anchorEl &&
        !anchorEl.contains(event.target as Node)
      ) {
        setAnchorEl(null);
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, anchorEl, onClose]);

  useEffect(() => {
    if (!open) {
      onClose();
    }
  }, [open, onClose]);

  return (
    <div>
      <div onClick={handleClick}>
        <SvgIcon className='optionsIcon'>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
            />
          </svg>
        </SvgIcon>
      </div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        ref={popperRef as React.Ref<HTMLDivElement>}
      >
        <Box
          sx={{
            border: 1,
            p: 1,
            bgcolor: 'background.paper',
            borderStyle: 'solid',
            borderRadius: 1,
            borderColor: '#FF6F00',
            minWidth: 100,
            ml: 3,
            mt: -3.375,
          }}
          onClick={() => {
            setAnchorEl(null);
            onClose();
          }}
        >
          {content}
        </Box>
      </Popper>
    </div>
  );
};

export default Tooltip;
