// /src/components/Modals/AddWorkspaceModal.tsx

// What? A modal for adding a new workspace to the application.
// Why? To allow users to add new workspaces to the application.
// How?
// - Uses a Modal component to display the modal.
// - Uses a ModalDialog component to display the modal content.
// - Uses a FormControl component to display the form.
// - Uses a FormLabel component to display the label for the workspace name input.
// - Uses an Input component to display the workspace name input.

import React, { useEffect } from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import { Button } from '@mui/joy';

// How to instantiate:
// <AddWorkspaceModal
//   open={open}
//   handleAddWorkspace={handleAddWorkspace}
//   onClose={onClose}
//   buttonDisabled={buttonDisabled}
//   buttonLabel={buttonLabel}
// />
interface AddWorkspaceModalProps {
  open: boolean;
  handleAddWorkspace: (event: React.FormEvent<HTMLFormElement>) => void;
  onClose: () => void;
  buttonDisabled: boolean; // New prop to disable the button
  buttonLabel: React.ReactNode; // New prop to display label or spinner
}

// What? A modal for adding a new workspace to the application.
// Why? To allow users to add new workspaces to the application.
// How?
// This component implements a modal for adding a new workspace by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Displaying a form within the modal with a field for workspace name
// 3. Managing form state (workspace name) using React hooks
// 4. Handling form submission and passing data to the parent component
// 5. Providing options to submit the form or cancel the operation
// 6. Resetting the form state when the modal is closed
// 
// The component uses various MUI Joy components to create a consistent UI,
// including Modal, ModalDialog, FormControl, Input, and Button.
const AddWorkspaceModal: React.FC<AddWorkspaceModalProps> = ({ open, handleAddWorkspace, onClose, buttonDisabled, buttonLabel }) => {
  const [addWorkspaceOpen, setAddWorkspaceOpen] = React.useState<boolean>(open);

  useEffect(() => {
    setAddWorkspaceOpen(open);
  }, [open]);

  return (
    <Modal open={addWorkspaceOpen} onClose={onClose}>
      <ModalDialog className="modal">
        <DialogTitle>Add new workspace</DialogTitle>
        <form onSubmit={handleAddWorkspace}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Workspace Name</FormLabel>
              <Input name='workspaceName' className="inputField" autoFocus required placeholder='Workspace Name' />
            </FormControl>
            <div className='btnCont'>
              <Button variant="solid" color="neutral" onClick={onClose} disabled={buttonDisabled}>Cancel</Button>
              {/* Disable the create button while the workspace is being created and show the spinner */}
              <Button variant="solid" color="primary" className='primaryBtn' type="submit" disabled={buttonDisabled}>
                {buttonLabel}
              </Button>
            </div>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default AddWorkspaceModal;