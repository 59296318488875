// src/views/Unverified.tsx

// What? A React component for displaying a message when a user's account is not verified.
// Why? To inform the user that their account needs to be verified before accessing certain features.
// How? The component's logic flow:
// 1. Initialize: Set up state for error message, fetch user data
// 2. API Interaction: Use useReadUser to fetch user details
// 3. User Interaction: Handle button click to refresh authentication
// 4. Navigation: Use useNavigate to redirect user after successful verification
// 5. Cleanup: Manage side effects with useEffect, ensure proper state updates
// 6. Error Handling: Display error message if refresh fails

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box } from '@mui/joy';
import { useAuth0 } from '@auth0/auth0-react';
import { useReadUser } from '../services/api';

const Unverified: React.FC = () => {

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const readUserAPI = useReadUser();
    const navigate = useNavigate();
    const location = useLocation();  // To store the previously requested URL
    const [error, setError] = useState<string | null>(null);  // Error handling if refresh fails

    // Get the previous URL that the user was trying to access before being sent to Unverified
    const previousUrl = location.state?.from || '/';  // If no previous URL, fallback to home page

    const handleClick = async () => {
        try {
          // Refresh the token
          await getAccessTokenSilently({ cacheMode: 'off' });
    
          // Fetch the user data after token refresh
          const userData = await readUserAPI();
    
          if (userData) {
            // Check if user status is no longer 'created'
            if (isAuthenticated && userData.status !== 'created') {
              // If user is authorized, navigate back to the originally requested page
              navigate(previousUrl);
            } else {
              setError("User account is still not activated.");
            }
          }
        } catch (err) {
          console.error('Error refreshing token or fetching user data:', err);
          setError('Failed to refresh authentication. Please try again.');
        }
      };

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                p: 3,
                marginTop: '80px',
                zIndex: 0,
                position: 'relative',
                overflowY: 'auto',
                backgroundColor: '#fff',
                padding: '20px',
                textAlign: 'center'
            }}
            className="tableBox">
              
            <h1>Unverified!</h1>
            <p>Your Auth0 account is not verified. Please check your inbox for a verification email and follow the instructions. Then click the button below to continue.</p>
            
            <Button onClick={handleClick}>
                I have verified my account
            </Button>

            {error && <p style={{ color: 'red' }}>{error}</p>}
        </Box>
    );
};

export default Unverified;
