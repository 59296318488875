// /src/components/NavContainerNoSidebar.tsx

// What? A container for the navigation bar without the workspace sidebar.
// Why? To display the navigation bar and the content of the container.
// How?
// - Uses the Upperbar component to display the upper bar.
// - Uses the children prop to display the content of the container.

import React, { useEffect } from 'react';
import Upperbar from './Upperbar';
import '../styles/MainContent.css';

interface NavContainerNoSidebarProps {
  children: React.ReactNode;
}

const NavContainerNoSidebarProps: React.FC<NavContainerNoSidebarProps> = ({ children }) => {

  useEffect(() => {
    // Reset sidebar width when this component is mounted
    document.documentElement.style.setProperty('--sidebar-width', '0px');
  }, []);
  
  return (
    <>
      <Upperbar />
      <div className='main-content-no-sidebar'>
        {/* Content */}
        {children}
      </div>
    </>
  );
};

export default NavContainerNoSidebarProps;
