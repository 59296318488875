// /src/components/NavContainer.tsx

// What? A container for the navigation bar.
// Why? To display the navigation bar and the workspace sidebar.
// How?
// - Uses the Upperbar component to display the upper bar.
// - Uses the WorkspaceSidebar component to display the workspace sidebar.
// - Uses the children prop to display the content of the container.

import React from 'react';
import Upperbar from './Upperbar';
import WorkspaceSidebar from './WorkspaceSidebar';
import '../styles/MainContent.css';

interface NavContainerProps {
  children: React.ReactNode;
}

const NavContainer: React.FC<NavContainerProps> = ({ children }) => {

  return (
    <>
      <Upperbar />
      <WorkspaceSidebar />
      <div className="main-content" >
        {/* Content */}
        {children}
      </div>
    </>
  );
};

export default NavContainer;