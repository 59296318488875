// /src/components/ChatMessage/ChatMessage.tsx

// What? A component for displaying chat messages in the application.
// Why? To display messages in a structured and styled manner.
// How?
// - Uses ReactMarkdown to render the message content.
// - Provides a foldable component for expanding and collapsing message details.
// - Handles different message types (text, source, citation, and status notifications).

import React from "react";
import ReactMarkdown from "react-markdown";
import { useState, useRef, useEffect, ReactNode } from 'react';

import styles from "./ChatMessage.module.css";

import LoadingDots from "./LoadingDots"


interface Props {
    message: Record<string, any>;
}

interface FoldableComponentProps {
  title: string;
  children: ReactNode;
}

// What? A foldable component for expanding and collapsing message details.
// Why? To provide a compact view of the message content and expand it on demand.
// How?
// - Uses a button to toggle the open/closed state.
// - Displays an arrow icon to indicate the current state.
// - Provides a container for the message details.

const FoldableComponent: React.FC<FoldableComponentProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className={styles.foldButtonContainer}>
      <button className={styles.foldButton} onClick={toggleOpen}>
        <span className={isOpen? styles.arrowOpen : styles.arrowClosed}>&#9664;</span>
        <span className={styles.foldButtonText}>{title}</span>
      </button>
      </div>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

interface MessageWithSourcesProps {
    message: Array<Record<string, any>>;
}

// What? A component for rendering a message with sources in the application.
// Why? To display the message content and its associated sources.
// How?
// - Uses a source map to track the source IDs and their corresponding citations.
// - Maps source IDs to their file IDs.
// - Renders the message content using ReactMarkdown.
// - Provides citations for the sources in the message.
const MessageWithSources: React.FC<MessageWithSourcesProps> = ({ message }) => {
    let sourceMap: Record<string, number> = {};
    let sourceFileMap: Record<string, string> = {};
    let count: number = 0;

    let lastCitation: number = -1;

    for (const part of message) {  // Now message is correctly typed as an array
        if (part.type === "text") {
            lastCitation = -1;
        }
        if (part.type === "source") {
            let sources: string[] = [part._id];
            if ("source_links" in part) {
                sources = part.source_links
                    .filter((obj: any) => obj.path)
                    .map((obj: any) => `/workspace/${obj.path.split("/")[0]}/file/${encodeURIComponent(obj.path)}`);
            }

            part["sources"] = [];
            for (const source of sources) {
                if (!(source in sourceMap)) {
                    count++;
                    sourceMap[source] = count;
                }

                if (sourceMap[source] !== lastCitation) {
                    part["sources"].push(source);
                    lastCitation = sourceMap[source];
                }
            }

            for (const source of sources) {
                if (!(source in sourceFileMap) && "source_file_id" in part) {
                    sourceFileMap[source] = part["source_file_id"];
                }
            }
        }
    }

    const get_param = (source: string) => {
        if (!(source in sourceFileMap)) {
            return "";
        }
        return `source_file_id=${encodeURIComponent(sourceFileMap[source])}`;
    };

    const renderMessage = (message: any) => {
        if (!Array.isArray(message)) {
            return null;
        }

        return message.map((part: Record<string, any>, index: number) => {
            if (part.type === "text") {
                return part.content
            }

            if (part.type === "source" && "url" in part) {
                return part.sources
                    .map((source: string, sourceIndex: number) => `[[${sourceMap[source]}]](${part.url})`)
                    .find((str: string) => str);
                    //.join('');
            }

            if (part.type === "source") {
                return part.sources
                    .map((source: string, sourceIndex: number) => `[[${sourceMap[source]}]](${source}?${get_param(source)})`)
                    .find((str: string) => str);
                    //.join('');
            }

            return null;
        }).join('');
    };

    return (
        <ReactMarkdown
            components={{
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer" className={styles.citation}>
                {props.children}
              </a>
            ),
          }}
        >
            {renderMessage(message)}
        </ReactMarkdown>
    );
};

// What? A component for rendering chat messages in the application.
// Why? To display messages in a structured and styled manner.
// How?
// - Uses ReactMarkdown to render the message content.
// - Provides a foldable component for expanding and collapsing message details.
// - Handles different message types (text, source, citation, and status notifications).
export const ChatMessage = ({ message }: Props) => {
    let renderedMessage: JSX.Element | null = null;
    let message_style = styles.backgroundAssistantMessage;

    // Show content if it exists
    if (message.content) {
        message_style = message.role === "user" ? styles.userMessage : styles.assistantMessage;
        // Pass message.content (an array) to MessageWithSources
        renderedMessage = (<MessageWithSources message={message.content} />);
    }

    // If completed (thought process)
    else if (message.status_notification && message.thought_process && message.status && message.status === "complete") {
        renderedMessage = (
            <FoldableComponent title={message.status_notification}>
                <MessageWithSources message={message.thought_process} />
            </FoldableComponent>
        );
    }

    // If completed (entries)
    else if (message.status_notification && message.entries && message.status && message.status === "complete") {
        renderedMessage = (<>{message.status_notification}</>);
    }

    // If completed (tool)
    else if (message.status_notification && message.tool && message.status && message.status === "complete") {
        renderedMessage = (<>{message.status_notification}</>);
    }

    // If in progress
    else if (message.status_notification && message.status && message.status === "in_progress") {
        renderedMessage = (<><span>{message.status_notification}</span><LoadingDots /></>);
    }

    else if (message.status_notification && message.status && message.status == "error") {
        renderedMessage = (<>{message.status_notification}</>);
    }

    if (renderedMessage === null) return (<></>);

    const container_style = message.role === "user" ? styles.userContainer : styles.assistantContainer;

    return (
        <div className={container_style}>
            <div className={message_style}>
                {renderedMessage}
            </div>
        </div>
    );
};
