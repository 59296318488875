import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useGetUsageTrends } from '../../services/api';
import '../../styles/UsageTrendsChart.css';

import { UsageTrendTimeframe } from '../../common/types';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UsageTrendsChart: React.FC = () => {
  const getUsageTrends = useGetUsageTrends();
  const [timeframe, setTimeframe] = useState('30');
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsageTrends(timeframe as UsageTrendTimeframe);
      setChartData(data);
    };
    fetchData();
  }, [timeframe]);

  const handleTimeframeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeframe(e.target.value);
  };

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card">
      <h2>Usage Trends</h2>
      <Line data={chartData} options={chartData.options} />
      <select className="selectBox" value={timeframe} onChange={handleTimeframeChange}>
        <option value="30">Last 30 days</option>
        <option value="90">Last 90 days</option>
        <option value="365">Last 365 days</option>
      </select>
    </div>
  );
};

export default UsageTrendsChart;