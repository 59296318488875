import React from "react";
import "../index.css";

interface PageStatusProps {
  children: React.ReactNode;
}

// Shows a full-page status message (such as Loading... or an error message).
// The message is centered on screen but offset by the header and sidebar.
const PageStatus: React.FC<PageStatusProps> = ({ children }) => {
  return (
    <div className="page-status">
      {children}
    </div>
  );
}
export default PageStatus;
