// /src/components/ChatMessage/LoadingDots.tsx

// What? A component for rendering loading dots in the application.
// Why? To provide a visual indicator of loading status in chat messages.
// How?
// - Uses a setInterval to update the dots every 500ms.
// - Provides a function to render the dots based on the current state.
import React from "react";
import { useState, useEffect } from 'react';

const render = (dots: string) => {
    const invisibleDots = Array.from({ length: 3 - dots.length }, (_, index) => (
        <span key={index} style={{ visibility: 'hidden' }}>.</span>
    ));

    return (
        <>
            {dots}
            {invisibleDots}
        </>
    );
}

const LoadingDots = () => {
    const [dots, setDots] = useState<string>('.');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => {
                switch (prevDots) {
                    case '.':
                        return '..';
                    case '..':
                        return '...';
                    case '...':
                        return '.';
                    default:
                        return '.';
                }
            });
        }, 500); // Change dots every 500ms

        return () => clearInterval(interval);
    }, []);

    return render(dots)
};

export default LoadingDots