// What? A React component for displaying a list of workspaces a user has access to.
// Why? To allow users to manage their workspaces, including creating new ones, deleting existing ones, and leaving workspaces.
// How? The component's logic flow:
// 1. Initialize: Set up state for workspaces, user data, loading, modal visibility, and user role
// 2. API Interaction: Use useReadUser to fetch user details including role, useUserWorkspaces to fetch workspaces
// 3. User Interaction: Handle form submission for adding new workspaces, open modals for deleting and leaving workspaces
// 4. Workspace Management: Implement functions for adding, deleting, and leaving workspaces
// 5. Access Control: Restrict certain actions (e.g., adding, deleting workspaces) based on user role
// 6. Error Handling: Display error message if fetching data fails
// 7. Cleanup: Manage side effects with useEffect, ensure proper state updates

import React, { useState, useEffect } from 'react';
import { useReadUser, useUserWorkspaces, useWorkspaceCreate, useWorkspaceDelete, useWorkspaceUserAcceptInvite, useWorkspaceUserRemove } from '../services/api';
import { Box, Button, Table, CircularProgress } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserWorkspacesType } from '../common/types'; 
import "../styles/UserWorkspaces.css";
import { SvgIcon } from '@mui/material';
import AddWorkspaceModal from '../components/Modals/AddWorkspaceModal';
import ConfirmationModal from '../components/Modals/ConfirmationModal'; 
import LogoutButton from '../components/LogoutButton';
import PageLoading from '../components/PageLoading';
import { Link } from 'react-router-dom';

const UserWorkspaces: React.FC = () => {
  const userWorkspacesAPI  = useUserWorkspaces();
  const workspaceCreateAPI = useWorkspaceCreate();
  const workspaceDeleteAPI = useWorkspaceDelete(); 
  const removeWorkspaceUser = useWorkspaceUserRemove();
  const acceptWorkspaceInvite = useWorkspaceUserAcceptInvite();
  const readUserAPI = useReadUser();

  const [userWorkspaces, setUserWorkspaces] = useState<UserWorkspacesType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);  // Spinner for loading data
  const [creatingWorkspace, setCreatingWorkspace] = useState<boolean>(false);  // Spinner for workspace creation
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [leaveModalOpen, setLeaveModalOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false); // Handle loading state for both delete and leave actions
  const [workspaceToDelete, setWorkspaceToDelete] = useState<string | null>(null);
  const [workspaceToLeave, setWorkspaceToLeave] = useState<string | null>(null);
  const [lastFetchTime, setLastFetchTime] = useState<number>(0);
  const FETCH_COOLDOWN = 1000; // 1 second cooldown

  const title = "Workspaces";

  // What? A function to fetch user workspaces and user data.
  // Why? To populate the workspace list and user details.
  // How?
  // - Sets loading to true to show a spinner.
  // - Fetches workspaces using userWorkspacesAPI.
  // - Updates the workspace list if data is received.
  // - Fetches user data using readUserAPI.
  // - Updates the user details if data is received.
  // - Sets loading to false after fetching data.
  const fetchData = async (force: boolean = false) => {
    const now = Date.now();
    if (!force && now - lastFetchTime < FETCH_COOLDOWN) {
      return;
    }
    
    try {
      setLoading(true);
      const data = await userWorkspacesAPI();
      if (data) {
        setUserWorkspaces(data);
      }

      const userData = await readUserAPI();
      if (userData) {
        setUser(userData);
      }
      setLastFetchTime(now);
    } catch (error: any) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // What? A function to handle the submission of a new workspace form.
  // Why? To add a new workspace to the user's list of workspaces.
  // How?
  // - Prevents default form submission behavior.
  // - Extracts workspace name from the form data.
  // - Calls addNewWorkspace to create the new workspace.
  const handleAddWorkspace = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const workspaceName = formData.get('workspaceName') as string;    
    await addNewWorkspace(workspaceName);
  };

  // What? A function to create a new workspace.
  // Why? To add a new workspace to the user's list of workspaces.
  // How?
  // - Sets creatingWorkspace to true to show a spinner.
  // - Calls workspaceCreateAPI to create the new workspace.
  // - Refreshes the workspace list after creation.
  // - Sets creatingWorkspace to false after creation.
  // - Closes the modal after creation.
  const addNewWorkspace = async (name: string) => {
    setCreatingWorkspace(true);  // Start creating spinner
    try {
      await workspaceCreateAPI(name);  // Wait for the backend to create workspace
      await fetchData();  // Refresh workspace list after creation
    } catch (error: any) {
      console.error('Failed to create workspace:', error);
    } finally {
      setCreatingWorkspace(false);  // Stop creating spinner
      setOpen(false);  // Close the modal only when table is updated
    }
  };

  // What? A function to open the delete modal for a workspace.
  // Why? To allow the user to confirm the deletion of a workspace.
  // How?
  // - Sets the workspace to delete and opens the modal.
  const openDeleteModal = (workspaceId: string) => {
    setWorkspaceToDelete(workspaceId);
    setDeleteModalOpen(true);
  };
  
  // What? A function to handle the confirmation of deleting a workspace.
  // Why? To delete a workspace from the user's list of workspaces.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Checks if the workspace to delete is valid.
  // - Calls workspaceDeleteAPI to delete the workspace.
  // - Refreshes the workspace list after deletion.
  // - Sets isProcessing to false after deletion.
  // - Closes the modal after deletion.
  const handleDeleteConfirmed = async () => {
    setIsProcessing(true);
    try {
      if (workspaceToDelete) {
        await workspaceDeleteAPI(workspaceToDelete);
        await fetchData();
      }
    } catch (error: any) {
      console.error('Failed to delete workspace:', error);
    } finally {
      setIsProcessing(false);
      setDeleteModalOpen(false);
    }
  };

  // What? A function to open the leave modal for a workspace.
  // Why? To allow the user to confirm the deletion of a workspace.
  // How?
  // - Sets the workspace to leave and opens the modal.
  const openLeaveModal = (workspaceId: string) => {
    setWorkspaceToLeave(workspaceId);
    setLeaveModalOpen(true); // Show confirmation to leave workspace
  };

  // What? A function to handle the confirmation of leaving a workspace.
  // Why? To remove the user from a workspace.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Checks if the workspace to leave is valid.
  // - Calls removeWorkspaceUser to remove the user from the workspace.
  // - Refreshes the workspace list after leaving.
  // - Sets isProcessing to false after leaving.
  // - Closes the modal after leaving.
  const handleLeaveConfirmed = async () => {
    setIsProcessing(true);
    try {
      if (workspaceToLeave && user.id) {
        await removeWorkspaceUser(workspaceToLeave, user.id);
        await fetchData();
      }
    } catch (error: any) {
      console.error('Failed to leave workspace:', error);
    } finally {
      setIsProcessing(false);
      setLeaveModalOpen(false);
    }
  };

  // What? A function to handle the acceptance of an invite to a workspace.
  // Why? To update the workspace status to 'Accepted'.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Checks if the workspace to accept is valid.
  // - Calls acceptWorkspaceInvite to update the workspace status.
  // - Refreshes the workspace list after accepting.
  // - Sets isProcessing to false after accepting.
  const handleAcceptInvite = async (workspace_id: string) => {
    try {
      await acceptWorkspaceInvite(workspace_id);
      await fetchData();
    } catch (error: any) {
      console.error('Failed to accept invite', error);
    }
  };

  // What? A function to remove a user from a workspace.
  // Why? To remove a user from a workspace.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Checks if the workspace and user to remove are valid.
  // - Calls removeWorkspaceUser to remove the user from the workspace.
  // - Refreshes the workspace list after removing.
  // - Sets isProcessing to false after removing.
  const handleRemoveUser = async (workspace_id: string, user_id: string) => {
    try {
      await removeWorkspaceUser(workspace_id, user_id);
      await fetchData();
    } catch (error: any) {
      console.error('Failed to remove user', error);
    }
  };

  // What? A function to format the expiry date.
  // Why? To display the expiry date in a readable format.
  // How?
  // - Converts the expiry date to a Date object.
  // - Extracts the year, month, and day.
  // - Returns the formatted date string.
  const formatExpiryDate = (expiryDate: string) => {
    const date = new Date(expiryDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // What? A function to capitalize the first letter of a string.
  // Why? To display the status in a readable format.
  // How?
  // - Extracts the first character and converts it to uppercase.
  // - Appends the rest of the string.
  // - Returns the formatted string.
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const editRoles = ['owner', 'admin', 'editor'];

  // What? A function to check if the user has edit permission.
  // Why? To restrict certain actions based on user role.
  // How?
  // - Checks if the user role is in the editRoles array.
  // - Returns true if the role is in the array, false otherwise.
  const userHaveEditPermission = (userRole: string) => {
    return editRoles.includes(userRole);
  };

  // What? A function to get the badge class based on the workspace status.
  // Why? To apply the appropriate styling to the status badge.
  // How?
  // - Converts the status to lowercase.
  // - Returns the corresponding badge class based on the status.
  const getStatusBadgeClass = (status: 'Accepted' | 'Invited' | 'Inactivated' | string): string => {
    switch (status.toLowerCase()) {
      case 'accepted':
        return 'active-badge';
      case 'invited':
        return 'pending-badge';
      case 'inactivated':
        return 'alert-badge';
      default:
        return 'inactive-badge';  // Fallback class for unknown statuses
    }
  };

  // TODO: Merge this code with code in WorkspaceProvider
  const getPermissions = (workspace: UserWorkspacesType) => {
    const hasAccepted = workspace?.permissions?.status === 'accepted'
    const isOwner = workspace?.permissions?.role === 'owner' && hasAccepted;
    const isAdmin = workspace?.permissions?.role === 'admin' && hasAccepted || isOwner;
    const isEditor = workspace?.permissions?.role === 'editor' && hasAccepted || isAdmin;
    const isUser = workspace?.permissions?.role === 'user' && hasAccepted || isEditor;
    const isOnlyOwner = workspace?.permissions?.is_only_owner;

    return {
      isOwner,
      isAdmin,
      isEditor,
      isUser,
      isOnlyOwner,
    };
  }

  const canUserLeaveWorkspace = (workspace: UserWorkspacesType) => {
    // Owners can only leave workspace if there is at least one other accepted owner
    const permissions = getPermissions(workspace)
    if (permissions.isOwner) {
      return !permissions.isOnlyOwner;
    }
    return true;
  };

  const myWorkspaces = userWorkspaces.filter((workspace) => getPermissions(workspace).isOwner);
  const otherWorkspaces = userWorkspaces.filter((workspace) => !getPermissions(workspace).isOwner);

  if(loading) 
    return <PageLoading>Loading Workspaces...</PageLoading>

  return (
    <Box component="main" className="tableBox">

      {/* Header with Add New button */}
      <div className='headerContainer'>
        <span className='title'>{title}</span>
        <Button 
          className='primaryBtn' 
          onClick={() => setOpen(true)} 
          startDecorator={<Add />} 
          disabled={creatingWorkspace} // Disable while creating
        >
          {creatingWorkspace ? <CircularProgress size="sm" className="customSpinner"/> : "Add New"}
        </Button>
      </div>

      {/* Section for My Workspaces */}
      <h2>My Workspaces</h2>
      <Table sx={{ '& thead th:nth-child(2)': { width: '40%' } }}>
        <thead>
          <tr>
            <th>Edit</th>
            <th>Workspace</th>
            <th>Indexing Status</th>
          </tr>
        </thead>
        <tbody>
          {myWorkspaces.length > 0 ? (
            myWorkspaces.map((workspace) => (
              <tr key={workspace.id}>
                <td>
                  {getPermissions(workspace).isEditor && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
                      <a
                        href={`/workspace/${workspace.id}`}
                        onClick={() => {
                          localStorage.setItem('workspaceName', workspace.name);
                        }}
                      >
                        <SvgIcon className='editIcon'>
                          <svg className='editIconSvg' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"/>
                          </svg>
                        </SvgIcon>
                      </a>
                      {getPermissions(workspace).isOwner && (
                        <Button className="iconButton" onClick={() => openDeleteModal(workspace.id)}>
                          <DeleteIcon className='trashIconSvg' />
                        </Button>
                      )}
                    </div>
                  )}
                </td>
                <td className='workspaceNameCont'>
                  <div className="workspaceName">
                    <Link 
                      to={`/workspace/${workspace.id}/chat`}
                      onClick={() => localStorage.setItem('workspaceName', workspace.name)}
                    >                   
                      <span>{workspace.name}</span>
                    </Link>
                  </div>
                </td>
                <td>
                  {workspace.indexing_status && <span>{capitalizeFirstLetter(workspace.indexing_status as string)}</span>}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: 'center' }}>No workspaces found</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Section for Other Workspaces */}
      <h2>Other Workspaces</h2>
      <Table sx={{ '& thead th:nth-child(2)': { width: '40%' } }}>
        <thead>
          <tr>
            <th>Edit</th>
            <th>Workspace</th>
            <th>Status</th>
            <th>Role</th>
            <th>Leave</th>
          </tr>
        </thead>
        <tbody>
          {otherWorkspaces.length > 0 ? (
            otherWorkspaces.map((workspace) => (
              <tr key={workspace.id}>
                <td>
                  {getPermissions(workspace).isEditor && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
                      <a
                        href={`/workspace/${workspace.id}`}
                        onClick={() => {
                          localStorage.setItem('workspaceName', workspace.name);
                        }}
                      >
                        <SvgIcon className='editIcon'>
                          <svg className='editIconSvg' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83l3.75 3.75z"/>
                          </svg>
                        </SvgIcon>
                      </a>
                      {getPermissions(workspace).isOwner && (
                        <Button className="iconButton" onClick={() => openDeleteModal(workspace.id)}>
                          <DeleteIcon className='trashIconSvg' />
                        </Button>
                      )}
                    </div>
                  )}
                </td>
                <td className='workspaceNameCont'>
                  <div className="workspaceName">
                    {workspace.permissions.status === 'invited' ? (
                      <span>{workspace.name}</span>
                    ) : (
                      <Link 
                        to={`/workspace/${workspace.id}/chat`}
                        onClick={() => localStorage.setItem('workspaceName', workspace.name)}
                      >                   
                        <span>{workspace.name}</span>
                      </Link>
                    )}
                  </div>
                </td>
                <td>
                  {workspace.permissions.status === 'invited' ? (
                    <span className={`status-badge ${getStatusBadgeClass(workspace.permissions.status)}`} onClick={() => { handleAcceptInvite(workspace.id); }}>
                      {capitalizeFirstLetter("Accept Invite")}
                    </span>
                  ) : (
                    <span className={`status-badge ${getStatusBadgeClass(workspace.permissions.status)}`}>
                      {capitalizeFirstLetter(workspace.permissions.status)}
                    </span>
                  )}
                </td>
                <td>{capitalizeFirstLetter(workspace.permissions.role)}</td>
                <td>
                  {workspace.permissions.status === 'accepted' ? (
                    canUserLeaveWorkspace(workspace) && (
                      <Button className="leaveWSCont" onClick={() => openLeaveModal(workspace.id)}>
                        <LogoutIcon />
                      </Button>
                    )) : (
                    <Button className="leaveWSCont" onClick={() => handleRemoveUser(workspace.id, user.id)}>
                      <LogoutIcon />
                    </Button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>No workspaces found</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* AddWorkspaceModal with modified behavior */}
      <AddWorkspaceModal 
        open={open} 
        handleAddWorkspace={handleAddWorkspace} 
        onClose={() => setOpen(false)} 
        buttonDisabled={creatingWorkspace}  // Disable button when creating
        buttonLabel={creatingWorkspace ? <CircularProgress size="sm" className="customSpinner"/> : "Add new"}  // Show spinner in button
      />
      {/* Confirmation Modal for Deleting Workspace */}
      <ConfirmationModal
        open={deleteModalOpen}
        title="Delete Workspace"
        message="Are you sure you want to delete this workspace? This action cannot be undone."
        actionLabel="Delete"
        cancelLabel="Cancel"
        onAction={handleDeleteConfirmed}  
        onClose={() => setDeleteModalOpen(false)} 
        loading={isProcessing}  
        spinner={<CircularProgress size="sm" className="customSpinner" />} 
      />
      {/* Confirmation Modal for Leaving Workspace */}
      <ConfirmationModal
        open={leaveModalOpen}
        title="Leave Workspace"
        message="Are you sure you want to leave this workspace? This action cannot be undone."
        actionLabel="Leave"
        cancelLabel="Cancel"
        onAction={handleLeaveConfirmed}  
        onClose={() => setLeaveModalOpen(false)}  
        loading={isProcessing} 
        spinner={<CircularProgress size="sm" className="customSpinner" />} 
      />
    </Box>
  );
};

export default UserWorkspaces;
