import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import PageStatus from "./PageStatus";
import "../index.css";

interface PageLoadingProps {
  children?: React.ReactNode;
}

const PageLoading: React.FC<PageLoadingProps> = ({ children }) => {
  return (
    <PageStatus>
      <CircularProgress className="page-status-icon"/>
      <Typography variant="h6" className="page-status-text">
        {children || "Loading..."}
      </Typography>
    </PageStatus>
  );
};

export default PageLoading;
