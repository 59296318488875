// What? A React component for the chat interface.
// Why? To provide a chat interface for the user.
// How? The component's logic flow:
// 1. Initialize: Set up state (chat history, polling status), fetch workspace details
// 2. API Interaction: Use useAuthFetch for authenticated calls to backend
// 3. User Input: Capture and send new messages, update local state
// 4. Real-time Updates: Poll for new messages using useCallback and setTimeout
// 5. UI Management: Render chat messages, auto-scroll to latest using useRef
// 6. Navigation: Use useNavigate for moving between different app views
// 7. Cleanup: Manage side effects with useEffect, ensure proper state updates
//
// This flow creates an interactive, real-time chat interface within the workspace.
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuthFetch } from '../../utils/useAuthFetch';
import { useParams, useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../providers/WorkspaceProvider';

import { ChatInput } from "../../components/ChatInput";
import { ChatMessage } from "../../components/ChatMessage";
import { ClearChatButton } from "../../components/ClearChatButton";

import styles from "./Chat.module.css";
import { CircularProgress } from '@mui/joy';
import PageLoading from '../../components/PageLoading';

const Chat: React.FC = () => {
	const authFetch = useAuthFetch();
	const navigate = useNavigate();
	const { workspace } = useWorkspaceContext();

	const [chatHistory, setChatHistory] = useState<Record<string, any>[]>([]);
	const [isPolling, setIsPolling] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const { workspace_id } = useParams<{ workspace_id: string }>();

	const chatHistoryRef = useRef(chatHistory);
	chatHistoryRef.current = chatHistory;

	const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (workspace) {
			setLoading(false);
		}
	}, [workspace]);

    /*
        Manage chat interface
    */
    const pollHistory = useCallback(async () => {
        const start = performance.now();
        try {
            const newHistory = await authFetch(
                `/api/workspace/assistant/chat?workspace_id=${workspace_id}&mode=async`, "get AI response",
                {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify(chatHistoryRef.current)
                }, 1000 * 30)

            if (chatHistoryRef.current.length > 0) {
                if (!chatHistoryRef.current[0]["_id"] && chatHistoryRef.current[0]["tid"] === newHistory[0]["tid"] || chatHistoryRef.current[0]["_id"] === newHistory[0]["_id"]) {
                    setChatHistory(newHistory);
                }
            }
        } catch (error: any) {
            // Stop polling on request error
            if (error.status && error.status >= 400 && error.status < 500) {
                if (chatHistoryRef.current.length) {
                    chatHistoryRef.current[chatHistoryRef.current.length - 1]["aborted"] = true
                }
            }
        }
        const end = performance.now();

        if (isComplete()) {
            setIsPolling(false)
        } else {
            setTimeout(pollHistory, Math.max(500 - (end - start), 10))
        }
	}, [authFetch, workspace_id]);

    const isComplete = () => {
        if (!chatHistoryRef.current.length)
            return true;

        const m = chatHistoryRef.current[chatHistoryRef.current.length - 1];
        return ("request_response" in m && m["request_response"]) || ("aborted" in m && m["aborted"]);
    };

	useEffect(() => {
		// Start polling if not already polling
		if (chatHistory.length >= 1 && isPolling === false && !isComplete()) {
			setIsPolling(true);
			pollHistory();
		}
	}, [isPolling, chatHistory, pollHistory]);

	/*
		User callbacks
	*/
	const clearChat = () => {
        setIsPolling(false);
		setChatHistory([]);
	};

	/*
		Helpers
	*/
	const shouldAddDefaultProgress = () => {
		if (chatHistoryRef.current.length === 0) {
			return false;
		}
		const m = chatHistoryRef.current[chatHistoryRef.current.length - 1];
		return !m.status_notification && !isComplete();
	};

	useEffect(() => {
        console.log(chatHistory)
		if (chatMessageStreamEnd.current) {
			chatMessageStreamEnd.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [chatHistory]);

	if (loading) {
		return <PageLoading>Loading Chat...</PageLoading>
	}

	return (
		<div className={styles.container}>
			<div className={styles.chatRoot}>
				<div className={styles.chatContainer}>
					{!chatHistory.length ? (
						<div className={styles.chatEmptyState}>
							<h1 className={styles.chatEmptyStateTitle}>{workspace ? workspace.name : <CircularProgress />}</h1>
						</div>
					) : (
						<div className={styles.chatMessageStream}>
							{chatHistory.map((message: any, index: number) => (
								<div key={index}>
									<ChatMessage message={message} />
								</div>
							))}
							{shouldAddDefaultProgress() && (
								<div>
									<ChatMessage message={{ role: "assistant", status: "in_progress", status_notification: "Metalmind is thinking" }} />
								</div>
							)}
							<div ref={chatMessageStreamEnd} />
						</div>
					)}

					<div className={styles.chatInput}>
						<div className={styles.chatRow}>
							<ChatInput
								clearOnSend
								placeholder="Ask me a question"
								disabled={isPolling}
								onSend={(content: string) => { setChatHistory([...chatHistory, { "tid": Date.now(), "role": "user", "content": [{ "type": "text", "content": content }] }]) }}
							/>
							<div className={styles.commandsContainer}>
								<ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!chatHistory.length || isPolling} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chat;
