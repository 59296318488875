// /src/components/UnsafeAuthProvider.tsx

// What? A provider for the unsafe authentication state.
// Why? To allow the user to log in without the need for Auth0.
// How?
// - Uses the useState hook to manage the state of the unsafe user name.
// - Uses the useEffect hook to save the unsafe user name to the local storage.
// - Uses the useLocation hook to get the current location.
// - Uses the useNavigate hook to navigate to the home page.

import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import UnsafeLoginModal from "./Modals/UnsafeLoginModal"

interface UnsafeAuthContextType {
  unsafeUserName: string;
  setUnsafeUserName: (value: string) => void;
}

const defaultContext: UnsafeAuthContextType = {
  unsafeUserName: "",
  setUnsafeUserName: () => {
    throw new Error("setUnsafeUserName function must be used within UnsafeAuthProvider");
  },
};

const UnsafeAuthContext = createContext<UnsafeAuthContextType>(defaultContext);

interface UnsafeAuthProviderProps {
  children: ReactNode;
}

export const UnsafeAuthProvider : React.FC<UnsafeAuthProviderProps> = ({ children }) => {
  const [unsafeUserName, setUnsafeUserName] = useState<string>(() => {
    const storedValue = localStorage.getItem('unsafe_auth_user_name');
    return storedValue !== null ? JSON.parse(storedValue) : '';
  });

  useEffect(() => {
    localStorage.setItem('unsafe_auth_user_name', JSON.stringify(unsafeUserName));
  }, [unsafeUserName]);

  const location = useLocation();
  const navigate = useNavigate()

  React.useEffect(() => {
  	if (location.pathname === '/logout') {
      setUnsafeUserName("")
      navigate("/")
    }
  }, [location]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    setUnsafeUserName(formData.get('userName') as string)
  };

  return (
    <UnsafeAuthContext.Provider value={{ unsafeUserName, setUnsafeUserName }}>
      {!unsafeUserName && (<UnsafeLoginModal handleLogin={handleLogin} />)}
      {unsafeUserName && (<>{children}</>)}
    </UnsafeAuthContext.Provider>
  );
};

export const useUnsafeAuthProvider = () => {
  const context = useContext(UnsafeAuthContext);
  if (!context) {
    throw new Error('useUnsafeAuthProvider must be used within a UnsafeAuthProvider');
  }
  return context;
};