// /src/components/Modals/UploadFileModal.tsx

// What? A modal for uploading files.
// Why? To allow users to upload files to a workspace.
// How?
// This component implements a modal for uploading files by:
// 1. Rendering a modal dialog when the 'open' prop is true
// 2. Using react-dropzone to handle file selection and drag-and-drop functionality
// 3. Checking for duplicate files against existing files in the workspace
// 4. Displaying a confirmation modal if duplicate files are detected
// 5. Handling file upload through the handleFilesUpload prop function
// 6. Managing UI states for drag-and-drop and confirmation interactions
// 7. Closing the modal after successful file upload or user cancellation

import React, { useState, useCallback } from 'react';
import { Modal, Box, Button } from '@mui/joy'; // Joy UI mports
import { useDropzone, FileWithPath } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ConfirmationModal from './ConfirmationModal'; // Import the confirmation modal

import { FileType } from '../../common/types';

// Need to set webkit directory to allow slecting folders when using the file picker
// Need <input {...getInputProps()} webkitdirectory="" directory="" /> to get file picker to select folders. However, then it can not select file any longer. 
/*declare module 'react' {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    webkitdirectory?: string;
    directory?: string
  }
}*/

interface UploadFileModalProps {
  open: boolean;
  onClose: () => void;
  handleFilesUpload: (files: FileType[]) => void;
  existingFiles: FileType[];
}

// What? A modal for uploading files.
// Why? To allow users to upload files to a workspace.
// How?
// This component implements a modal for uploading files by:
// 1. Rendering a modal dialog when the 'open' prop is true (handled by Modal component)
// 2. Using react-dropzone to handle file selection and drag-and-drop functionality (useDropzone hook)
// 3. Checking for duplicate files against existing files in the workspace (handleDrop function)
// 4. Displaying a confirmation modal if duplicate files are detected (ConfirmationModal component)
// 5. Handling file upload through the handleFilesUpload prop function (handleConfirmReplace and handleCancelReplace functions)
// 6. Managing UI states for drag-and-drop interactions (setDragging state and useDropzone options)
// 7. Closing the modal after successful file upload or user cancellation (onClose function)
// 8. Handling clicks outside the modal to close it (handleBackdropClick function)

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  open,
  onClose,
  handleFilesUpload,
  existingFiles,
}) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [duplicateFiles, setDuplicateFiles] = useState<FileType[]>([]); // Store duplicate files
  const [newFiles, setNewFiles] = useState<FileType[]>([]); // Store non-duplicate files

  // What? Handle files dropped or selected by the user
  // Why? To check for duplicates based on both name and path
  // How?
  // - Use `webkitRelativePath` to distinguish files with the same name but different paths
  // - Compare against existing files with both name and path
  const handleDrop = useCallback((acceptedFiles: FileWithPath[]) => {

    const normalizePath = (path: string): string => {
      if (path.startsWith('./')) {
          path = path.substring(2);
      }
      if (path.startsWith('/')) {
          path = path.substring(1)
      }

      path = path.replace(/\/+/g, '/');
      return path;
    };

    const convertedFiles: FileType[] = acceptedFiles.map((file: FileWithPath) => {
      const id: string = normalizePath(file.path as string)

      return {
        id: id,
        node_type: "file",
        file: file,
        isUploading: true
      } as FileType
    });

    // Find new files
    const unique: FileType[] = convertedFiles.filter(convertedFile =>
      existingFiles.every(existingFile => existingFile.id !== convertedFile.id)
    );

    // Find duplicate files
    const intersect: FileType[] = existingFiles.filter(existingFile =>
      convertedFiles.some(convertedFile => convertedFile.id === existingFile.id)
    );

    // Mark updated files for upload
    for (const file of existingFiles) {
      const duplicateFile: FileType | undefined = convertedFiles.find(convertedFile => convertedFile.id === file.id);
      if (duplicateFile) {
        file.isUploading = true
        file.file = duplicateFile.file
      }
    }

    setNewFiles(unique)
    setDuplicateFiles(intersect)

    // Directly upload files if there are no duplicates
    if (intersect.length == 0) {
      handleFilesUpload([...existingFiles, ...unique])
      setDuplicateFiles([])
    }
  }, [existingFiles, handleFilesUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    onDragEnter: () => setDragging(true),
    onDragLeave: () => setDragging(false),
  });

  // Confirm replacement of duplicate files
  const handleConfirmReplace = () => {
    handleFilesUpload([...existingFiles, ...newFiles])
    setDuplicateFiles([])
    onClose();
  };

  // Cancel the upload of duplicates, only upload new files
  const handleCancelReplace = () => {
    for (const file of duplicateFiles) {
      file.isUploading = false
    }

    handleFilesUpload([...existingFiles, ...newFiles])
    setDuplicateFiles([])
    onClose();
  };

  // Handle clicking outside the modal content to close the modal
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <Box
          onClick={handleBackdropClick} // Listen for clicks on the modal backdrop
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            padding: '20px',
          }}
        >
          <Box
            {...getRootProps()}
            sx={{
              border: `2px dashed ${isDragActive || dragging ? '#FF4500' : '#FF6F00'}`,
              padding: '40px', // Larger padding for bigger dropzone
              borderRadius: '10px',
              width: '60%',
              maxWidth: '600px',
              backgroundColor: 'var(--primary-bg-color)', // Ensure the dropzone stands out
              transition: 'border-color 0.3s ease-in-out',
            }}
          >
            <p>Drag and drop files or folders here or click the button below to select individual files</p>
            <input {...getInputProps()} />
            <Button
              variant="solid"
              className="primaryBtn"
              startDecorator={<CloudUploadIcon />}
              sx={{ marginTop: '16px' }}
            >
              Select Files
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Modal for Duplicate Files */}
      <ConfirmationModal
        open={duplicateFiles.length > 0 && open}
        title={`Replace ${duplicateFiles.length} file${duplicateFiles.length > 1 ? 's' : ''}?`}
        message={`You are about to replace ${duplicateFiles.length} file${
          duplicateFiles.length > 1 ? 's' : ''
        } that already exist in the folder. Do you want to continue?`}
        actionLabel="Replace"
        cancelLabel="Cancel"
        onAction={handleConfirmReplace}
        onClose={handleCancelReplace}
      />
    </>
  );
};

export default UploadFileModal;
