// /src/components/ClearChatButton/ClearChatButton.tsx

// What? A component for a button to clear the chat in the application.
// Why? To allow users to clear the chat history.
// How?
// - Uses an IconButton to display the clear button.
// - Provides a Typography component to display the button text.
import React from "react";
import { Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./ClearChatButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`} onClick={onClick}>
            <IconButton className="clearButton" disabled={disabled} aria-label="clear chat">
                <DeleteIcon className="clearButton"/>
            </IconButton>
            <Typography style={{ width: 'max-content' }}>{"Clear chat"}</Typography>
        </div>
    );
};