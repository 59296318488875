import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { WorkspaceType } from '../common/types';
import { useWorkspaceRead } from '../services/api';

interface WorkspaceContextType {
  workspace: WorkspaceType | null;
  loading: boolean;
  isOwner: boolean;
  isAdmin: boolean;
  isEditor: boolean;
  isUser: boolean;
  isOnlyOwner: boolean;
}

const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error('useWorkspaceContext must be used within a WorkspaceProvider');
  }
  return context;
};

export const WorkspaceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [workspace, setWorkspace] = useState<WorkspaceType | null>(null);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    isOwner: false,
    isAdmin: false,
    isEditor: false,
    isUser: false,
    isOnlyOwner: false
  });

  const { workspace_id } = useParams();
  const workspaceReadAPI = useWorkspaceRead();

  useEffect(() => {
    let isActive = true;

    const fetchWorkspace = async () => {
      if (!workspace_id) {
        setLoading(false);
        return;
      }

      try {
        const data = await workspaceReadAPI(workspace_id);
        if (!isActive) return;

        setWorkspace(data);
        if (data.name) {
          localStorage.setItem('workspaceName', data.name);
        }
        setPermissions({
          isOwner: data.permissions?.role === 'owner',
          isAdmin: ['owner', 'admin'].includes(data.permissions?.role || ''),
          isEditor: ['owner', 'admin', 'editor'].includes(data.permissions?.role || ''),
          isUser: true,
          isOnlyOwner: Boolean(data.permissions?.is_only_owner)
        });
      } catch (error) {
        console.error('Failed to fetch workspace:', error);
      } finally {
        if (isActive) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchWorkspace();

    return () => {
      isActive = false;
    };
  }, [workspace_id]);

  return (
    <WorkspaceContext.Provider value={{
      workspace,
      loading,
      ...permissions
    }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
