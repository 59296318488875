// src

// What? A React component for managing a workspace's settings.
// Why? To allow the user to edit the workspace's description and assistant instructions.
// How?
// - Fetches workspace data using workspaceReadAPI.
// - Updates workspace data using workspaceUpdateAPI.
// - Handles beforeunload events to prevent data loss.
// - Displays the workspace's description and assistant instructions.
// - Allows the user to edit and save the description and assistant instructions.

import React, { useState, useEffect } from 'react';
import { useWorkspaceUpdate } from '../services/api';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Sheet, TextField  } from '@mui/joy';
import { WorkspaceType } from '../common/types'; 
import Textarea from '@mui/joy/Textarea';
import { useBeforeUnload } from 'react-router-dom';
import { Input } from '@mui/joy';

import { useWorkspaceContext } from '../providers/WorkspaceProvider'; // Import role-based context

const Workspace = () => {
    const workspaceUpdateAPI = useWorkspaceUpdate();
    const { workspace_id } = useParams<{ workspace_id: string }>();
    const { workspace } = useWorkspaceContext();  // Get workspace from context
    const [description, setDescription] = useState<string>(workspace?.description || ''); 
    const [assistantInstructions, setAssistantInstructions] = useState<string>(workspace?.assistant_instructions || ''); 
    const [initialDescription, setInitialDescription] = useState<string>(workspace?.description || '');
    const [initialAssistantInstructions, setInitialAssistantInstructions] = useState<string>(workspace?.assistant_instructions || '');
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [workspaceName, setWorkspaceName] = useState<string>(workspace?.name || ''); 
    const [initialWorkspaceName, setInitialWorkspaceName] = useState<string>(workspace?.name || '');

    const viewName = "Pre Prompt";
    const title = workspaceName ? `${workspaceName} > ${viewName}` : viewName;

    const { isAdmin } = useWorkspaceContext();
  
    // What? A function to check if the workspace data has changed.
    // Why? To determine if the user has made any changes to the workspace data.
    // How?
    // - Compares the current description and assistant instructions with the initial values.
    // - Sets isChanged to true if there are changes, false otherwise.
    useEffect(() => {
        if (
            description !== initialDescription ||
            assistantInstructions !== initialAssistantInstructions ||
            workspaceName !== initialWorkspaceName
        ) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [description, assistantInstructions, workspaceName, initialDescription, initialAssistantInstructions, initialWorkspaceName]);
    
    // What? A function to handle the saving of workspace data.
    // Why? To update the workspace data in the API.
    // How?
    // - Checks if the workspace ID is valid.
    // - Tries to update the workspace data using workspaceUpdateAPI.
    // - Logs any errors that occur during the update process.
    const handleSave = async () => {
        if (workspace_id) {
          try {
            await workspaceUpdateAPI(workspace_id, {
                name: workspaceName,
                description,
                assistant_instructions: assistantInstructions,
            });
            
            setInitialDescription(description);  
            setInitialAssistantInstructions(assistantInstructions);
            setIsChanged(false);  
          } catch (error: any) {
            console.error('Failed to update workspace', error);
          }
        }
    };

    // What? A function to enable the beforeunload warning only if there are unsaved changes.
    // Why? To prevent data loss if the user navigates away from the page without saving.
    // How?
    // - Adds an event listener for the beforeunload event.
    // - Sets the event return value to show the warning message.
    // - Removes the event listener when the component is unmounted.
    useEffect(() => {
        if (isChanged) {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                event.returnValue = ''; // Show the warning message
            };

            window.addEventListener('beforeunload', handleBeforeUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [isChanged]);

    useEffect(() => {
        if (workspace) {
            setDescription(workspace.description || '');
            setAssistantInstructions(workspace.assistant_instructions || '');
            setWorkspaceName(workspace.name || '');
            setInitialDescription(workspace.description || '');
            setInitialAssistantInstructions(workspace.assistant_instructions || '');
            setInitialWorkspaceName(workspace.name || '');
            setIsChanged(false);
        }
    }, [workspace]);

    return (
        <Box sx={{ display: 'flex' }} className="tableBox">
            <Box component="main">
                <div className='headerContainer'>
                    <span className='title'>{title}</span>
                </div>
                <Sheet className="dataEntryBG" sx={{ width: 500, padding: '10px', marginTop: '20px' }}>
                    <Input
                        placeholder="Workspace Name"
                        value={workspaceName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWorkspaceName(e.target.value)}
                        sx={{ marginBottom: '10px' }}
                        readOnly={!isAdmin}
                    />
                    <Textarea
                        sx={{ marginBottom: '10px', height: '300px' }}
                        placeholder="Add a Description" 
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        readOnly={!isAdmin}
                    />
                    {isAdmin && <Textarea 
                        sx={{ marginBottom: '10px', height: '300px' }}
                        placeholder="Add assistant instructions" 
                        value={assistantInstructions}
                        onChange={(e) => setAssistantInstructions(e.target.value)}
                    />}
                    {isAdmin && <Button 
                        className={!isChanged ? 'disabledBtn' : 'primaryBtn'}  // Add a class to style it properly
                        disabled={!isChanged}
                        onClick={handleSave}
                    >
                        Save
                    </Button>}
                </Sheet>
            </Box>
        </Box>
    );
};

export default Workspace;
