// src/views/WorkspaceFiles.tsx

// What? A React component for managing files in a workspace.
// Why? To allow the user to upload, delete, and view files in a workspace.
// How?
// - Fetches the workspace files using workspaceFiles.
// - Displays the files in a table.
// - Allows the user to upload files.
// - Allows the user to delete files.

import React, { useState, useEffect } from 'react';
import { useListFiles, useDeleteFile, useUploadFile } from '../services/api';
import { useParams } from 'react-router-dom';
import { Box, Table, Button as JoyButton, CircularProgress } from '@mui/joy';
import { FileType } from '../common/types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../styles/WorkspaceFiles.css';
import UploadFileModal from '../components/Modals/UploadFileModal';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import PageLoading from '../components/PageLoading';
import PQueue from 'p-queue';

const WorkspaceFiles = () => {
  const workspaceFiles = useListFiles();
  const deleteFile = useDeleteFile();
  const uploadFileAPI = useUploadFile();
  const { workspace_id } = useParams() as { workspace_id: string };
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [files, setFiles] = useState<FileType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const viewName = 'Files'; // Change this accordingly for each view

  useEffect(() => {
    const storedWorkspaceName = localStorage.getItem('workspaceName');
    if (storedWorkspaceName) {
      setWorkspaceName(storedWorkspaceName);
    }
  }, []);

  const title = workspaceName ? `${workspaceName} > ${viewName}` : viewName;

  // What? A function to fetch the workspace files from the API.
  // Why? To populate the workspace files in the component.
  // How?
  // - Sets loading to true to show a spinner.
  // - Tries to fetch the workspace files using workspaceFiles.
  // - Updates the workspace files in the state.
  // - Sets loading to false after fetching the data.
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await workspaceFiles(workspace_id);
      if (data) {
        setFiles(data);
      }
    } catch (error) {
      console.error('Failed to fetch files', error);
    } finally {
      setLoading(false);
    }
  };

  // What? A function to handle the deletion of a file.
  // Why? To allow the user to delete a file from the workspace.
  // How?
  // - Sets isProcessing to true to show a spinner.
  // - Tries to delete the file using deleteFile.
  // - Updates the workspace files in the state.
  // - Closes the delete modal.
  // - Sets isProcessing to false after deletion.
  const handleDelete = async () => {
    setIsProcessing(true);
    try {
      await deleteFile(workspace_id, fileId);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      setDeleteOpen(false);
    } catch (error) {
      console.error('Failed to delete file', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const isUploading = () => {
    return files.some(file => file.isUploading === true);
  }

  // What? A function to handle the upload of files.
  // Why? To allow the user to upload files to the workspace.
  const handleFilesUpload = async (uploadedFiles: FileType[], maxParallelUploads = 3) => {
    closeUploadModal();
    setFiles(uploadedFiles);

    const processUpload = async(file: FileType) => {
      if (file.isUploading && file.file) {
        try {
          const uploadedFile: FileType = await uploadFileAPI(workspace_id, file.file as File)
          uploadedFiles = uploadedFiles.map(existingFile => existingFile.id === uploadedFile.id ? uploadedFile : existingFile)
        } catch (error) {
          if (!file.last_modified) {
            uploadedFiles = uploadedFiles.filter(existingFile => existingFile.id !== file.id);
          }
          else {
            file.isUploading = false
            uploadedFiles = uploadedFiles.map(existingFile => existingFile.id === file.id ? file : existingFile)
          } 
        }
        finally {
          setFiles(uploadedFiles)
        }
      }
    }

    const queue = new PQueue({ concurrency: maxParallelUploads });
    for (const file of uploadedFiles) {
      queue.add(() => processUpload(file))
    };

    await queue.onIdle();
  };

  const openUploadModal = () => setModalOpen(true);
  const closeUploadModal = () => setModalOpen(false);

  useEffect(() => {
    if (workspace_id) {
      fetchData();
    }
  }, [workspace_id]);

  if(loading) 
    return <PageLoading>Loading Files...</PageLoading>

  return (
    <Box sx={{ display: 'flex' }} className="tableBox">
      <Box component="main">
        <div className="headerContainer">
          <span className="title">{title}</span>
          <JoyButton
            variant="solid"
            startDecorator={<CloudUploadIcon style={{ marginRight: '8px' }} />}
            onClick={openUploadModal}
            className="primaryBtn"
            disabled={loading || isUploading()}
          >
            Upload file
          </JoyButton>
        </div>

        <Table sx={{ '& thead th:nth-child(1)': { width: '40%' } }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Delete</th>
              <th>Uploaded At</th>
            </tr>
          </thead>
          <tbody>
            {files.length > 0 ? (
              files.map((file) => (
                <tr key={file.id}>
                  <td className="workspaceName">{file.id}</td>
                  <td>
                    {file.isUploading ? (
                      <DeleteOutlineIcon style={{ opacity: 0.5, cursor: 'not-allowed' }} />
                    ) : (
                      <div
                        className="deleteFile"
                        onClick={() => {
                          setFileId(file.id);
                          setDeleteOpen(true); // Open delete modal
                        }}
                      >
                        <DeleteOutlineIcon />
                      </div>
                    )}
                  </td>
                  <td>
                    {file.isUploading ? (
                      <CircularProgress size="sm" className="customSpinner" />
                    ) : file.last_modified ? (
                      new Date(file.last_modified).toLocaleString()
                    ) : (
                      'Unknown'
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No files available</td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Upload modal */}
        <UploadFileModal
          open={modalOpen}
          onClose={closeUploadModal}
          handleFilesUpload={(files: FileType[]) => handleFilesUpload(files, 3)}
          existingFiles={files}
        />

        {/* Confirmation Modal for deleting files */}
        <ConfirmationModal
          open={deleteOpen}
          title="Delete File"
          message="Are you sure you want to delete this file? This action cannot be undone."
          actionLabel="Delete"
          cancelLabel="Cancel"
          onAction={handleDelete}
          onClose={() => setDeleteOpen(false)}
          loading={isProcessing}
          spinner={<CircularProgress size="sm" className="customSpinner" />}
        />
      </Box>
    </Box>
  );
};

export default WorkspaceFiles;
