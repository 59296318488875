import React from "react";
import { Typography } from "@mui/material";
import PageStatus from "./PageStatus";
import { Error } from '@mui/icons-material';
import "../index.css";

interface PageErrorProps {
  children: React.ReactNode;
}

const PageError: React.FC<PageErrorProps> = ({ children }) => {
  return (
    <PageStatus>
      <Error className="page-status-icon" />
      <Typography variant="h6" className="page-status-text">
        {children || "An unspecified error has occured. Please try again."}
      </Typography>
    </PageStatus>
  );
};

export default PageError;
